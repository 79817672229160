<template>
  <div id="mon_compte" class="uk-width-1-1">
      <h2 class="gr-texte-black uk-margin-small-bottom">MON COMPTE</h2>
      <h3 class="gr-texte-black uk-margin-small-top">MES INFORMATIONS</h3>
      <div id="mon_compte_infos" uk-grid>
          <div class="uk-width-1-1 uk-width-4-5@s uk-margin-remove" uk-grid>
              <div class="uk-width-1-2">
                  <div class="label">Nom</div>
                  <div class="value gr-texte-black uk-text-break">{{nom}}</div>
              </div>
              <div class="uk-width-1-2">
                  <div class="label">Prénom</div>
                  <div class="value gr-texte-black uk-text-break">{{prenom}}</div>
              </div>
              <div class="uk-width-1-2">
                  <div class="label">Email</div>
                  <div class="value gr-texte-black uk-text-break">{{email}}</div>
              </div>
              <div class="uk-width-1-2">
                  <div class="label">Téléphone</div>
                  <div class="value gr-texte-black uk-text-break">{{telephone}}</div>
              </div>
              <div class="uk-width-1-1">
                  <div class="label">Adresse</div>
                  <div class="value gr-texte-black uk-text-break">{{adresse}}</div>
              </div>
              <div class="uk-width-1-2">
                  <div class="label">Code Postal</div>
                  <div class="value gr-texte-black uk-text-break">{{codepostal}}</div>
              </div>
              <div class="uk-width-1-2">
                  <div class="label">Ville</div>
                  <div class="value gr-texte-black uk-text-break">{{ville}}</div>
              </div>
          </div>
          <div class="uk-width-1-5 uk-hidden@s"></div>
      </div>
      <div class="uk-flex uk-flex-column uk-flex-top">
          <button @click="changeStepMC('mesinformationsform')" class="uk-button cta-sfr-primary uk-margin-large-top uk-margin-small-bottom">MODIFIER MES INFORMATIONS</button>
          <button @click="changeStepMC('passwordform')" class="uk-button cta-sfr-light">MODIFIER MON MOT DE PASSE</button>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'MonCompte',
  data() {
    return {
      nom: "",
      prenom: "",
      adresse: "",
      codepostal: "",
      ville: "",
      email: "",
      telephone:""
    }
  },
  mounted(){
    if(this.authenticated&&this.tokenAuth!=""){
      //var vm = this;
      axios.post(this.$store.state.urlws+'mesInfos',{'codesession': this.codesession},{headers:{Authorization:'Bearer '+this.tokenAuth+''}})
      .then(response => {
        this.nom=response.data.infos.nom;
        this.prenom=response.data.infos.prenom;
        this.adresse=response.data.infos.adresse;
        this.codepostal=response.data.infos.codepostal;
        this.ville=response.data.infos.ville;
        this.email=response.data.infos.email;
        this.telephone=response.data.infos.telephone;
      })
      .catch(e => {
        if(e.code=="ERR_NETWORK" && !e.response.data){
          this.$store.commit('deconnexion');
        }
        else{
          let message = "Une erreur est survenue.";
          if(e.response){
            if(e.response.data){
              if(e.response.data.action=="deconnexion"){
                this.$store.commit('deconnexion');
              }
            }
            this.errors=message;
          }
        }

      })
    }
    else{
    }
  },
  computed: {
    tokenAuth () {
      return this.$store.state.tokenAuth
    },
    authenticated() {
      return this.$store.state.authenticated
    },
    codesession() {
      return this.$store.state.codesession
    },
  },
  methods: {
      changeStepMC(label){
        this.$store.commit('gotoStepMC',{stepMC:label});
      },
  }
}
</script>
