<template>
  <div id="mon_compte" class="uk-width-1-1">
      <h2 class="gr-texte-black uk-margin-small-bottom">MON COMPTE</h2>
      <h3 class="gr-texte-black uk-margin-small-top">MES INFORMATIONS</h3>
      <div id="mon_compte_infos" uk-grid>
          <div class="uk-width-1-1 uk-width-4-5@s uk-margin-remove" uk-grid>
              <div class="uk-width-1-2">
                <label class="uk-form-label gr-form-label gr-texte-black" for="form-stacked-nom">Nom*</label>
                <div class="uk-form-controls">
                    <input class="uk-input uk-width-1-1 gr-form-input" v-model="input.nom" name="nom"  id="form-stacked-nom" type="text" placeholder="Votre nom">
                    <span class="error-message">{{error.nom}}</span>
                </div>
              </div>
              <div class="uk-width-1-2">
                <label class="uk-form-label gr-form-label gr-texte-black" for="form-stacked-prenom">Prénom*</label>
                <div class="uk-form-controls">
                    <input class="uk-input uk-width-1-1 gr-form-input gr-form-input" v-model="input.prenom" name="prenom" id="form-stacked-prenom" type="text" placeholder="Votre prénom">
                    <span class="error-message">{{error.prenom}}</span>
                </div>
              </div>
              <div class="uk-width-1-2">
                <label class="uk-form-label gr-form-label gr-texte-black" for="form-stacked-email">Email*</label>
                <div class="uk-form-controls">
                    <input class="uk-input uk-width-1-1 gr-form-input" v-model="input.email" name="email" id="form-stacked-email" type="text" placeholder="Votre email">
                    <span class="error-message">{{error.email}}</span>
                </div>
              </div>
              <div class="uk-width-1-2">
                <label class="uk-form-label gr-form-label gr-texte-black" for="form-stacked-telephone">Téléphone*</label>
                <div class="uk-form-controls">
                    <input class="uk-input uk-width-1-1 gr-form-input" v-model="input.telephone" name="telephone" id="form-stacked-telephone" type="text" placeholder="Votre téléphone">
                    <span class="error-message">{{error.telephone}}</span>
                </div>
              </div>
              <div class="uk-width-1-1">
                <label class="uk-form-label gr-form-label gr-texte-black" for="form-stacked-adresse">Adresse*</label>
                <div class="uk-form-controls">
                    <input class="uk-input uk-width-1-1 gr-form-input" v-model="input.adresse" name="adresse" id="form-stacked-adresse" type="text" placeholder="Numéro, nom de la rue">
                    <span class="error-message">{{error.adresse}}</span>
                </div>
              </div>
              <div class="uk-width-1-2">
                <label class="uk-form-label gr-form-label gr-texte-black" for="form-stacked-codepostal">Code postal*</label>
                <div class="uk-form-controls">
                    <input class="uk-input uk-width-1-1 gr-form-input" v-model="input.codepostal" name="codepostal" id="form-stacked-codepostal" type="text" placeholder="Votre code postal">
                    <span class="error-message">{{error.codepostal}}</span>
                </div>
              </div>
              <div class="uk-width-1-2">
                <label class="uk-form-label gr-form-label gr-texte-black" for="form-stacked-text">Ville*</label>
                <div class="uk-form-controls">
                    <input class="uk-input uk-width-1-1 gr-form-input" v-model="input.ville" name="ville" id="form-stacked-text" type="text" placeholder="Votre code ville">
                    <span class="error-message">{{error.ville}}</span>
                </div>
              </div>
              <div class="uk-width-1-2">
                <label class="uk-form-label gr-form-label gr-texte-black" for="form-stacked-text">Mot de passe*</label>
                <div class="uk-form-controls">
                    <input class="uk-input uk-width-1-1 gr-form-input" v-model="input.password" name="password" id="form-stacked-text" type="password" placeholder="Votre mot de passe">
                    <span class="error-message">{{error.password}}</span>
                </div>
              </div>
              <div class="uk-margin uk-width-1-1">
                <div v-if="errors" class="uk-margin uk-alert-danger" uk-alert>
                   <a class="uk-alert-close" uk-close></a>
                  {{errors}}
                </div>
              </div>
          </div>
          <div class="uk-width-1-5 uk-hidden@s"></div>
      </div>
      <div class="uk-flex uk-flex-column uk-flex-top">
          <button @click="registerMod()" class="uk-button cta-sfr-primary uk-margin-large-top uk-margin-small-bottom">Enregistrer</button>
          <button @click="changeStepMC('mesinformations')" class="uk-button cta-sfr-light">Annuler</button>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'MonCompteMod',
  data() {
    return {
      input: {
        nom: "",
        prenom: "",
        adresse: "",
        codepostal: "",
        ville: "",
        email: "",
        telephone:"",
        password:"",
      },
      errors:null,
      error:{
        nom: "",
        prenom: null,
        adresse: null,
        codepostal: null,
        ville: null,
        email: null,
        telephone:null,
        password:null,
      }
    }
  },
  mounted(){
    if(this.authenticated&&this.tokenAuth!=""){
      //var vm = this;
      axios.post(this.$store.state.urlws+'mesInfos',{'codesession': this.codesession},{headers:{Authorization:'Bearer '+this.tokenAuth+''}})
      .then(response => {
        this.input.nom=response.data.infos.nom;
        this.input.prenom=response.data.infos.prenom;
        this.input.adresse=response.data.infos.adresse;
        this.input.codepostal=response.data.infos.codepostal;
        this.input.ville=response.data.infos.ville;
        this.input.email=response.data.infos.email;
        this.input.telephone=response.data.infos.telephone;
      })
      .catch(e => {
        let f=e;
      })
    }
    else{
    }
  },
  computed: {

    tokenAuth () {
      return this.$store.state.tokenAuth
    },
    authenticated() {
      return this.$store.state.authenticated
    },
    codesession() {
      return this.$store.state.codesession
    },
  },
  methods: {
      changeStepMC(label){
        this.$store.commit('gotoStepMC',{stepMC:label});
      },
      registerMod(){
        if(this.input.email != "" && this.input.password != ""
         && this.input.nom != "" && this.input.prenom != "" && this.input.adresse != ""
          && this.input.codepostal != "" && this.input.ville != "" && this.input.telephone != "") {
        axios.put(this.$store.state.urlws+'user',
          {
            email:this.input.email,
            password:this.input.password,
            nom:this.input.nom,
            prenom:this.input.prenom,
            adresse:this.input.adresse,
            codepostal:this.input.codepostal,
            ville:this.input.ville,
            telephone:this.input.telephone,
            codesession:this.codesession,
          },{headers:{Authorization:'Bearer '+this.tokenAuth}}
        )
        .then(response => {
          this.changeStepMC("mesinformations");
          // JSON responses are automatically parsed.
          //this.posts = response.data

        })
        .catch(e => {
          this.error.nom = e.response.data.messages.errors.nom;
          this.error.prenom = e.response.data.messages.errors.prenom;
          this.error.ville = e.response.data.messages.errors.ville;
          this.error.codepostal = e.response.data.messages.errors.codepostal;
          this.error.adresse = e.response.data.messages.errors.adresse;
          this.error.password = e.response.data.messages.errors.password;
          this.error.confirm_password = e.response.data.messages.errors.confirm_password;
          this.error.email = e.response.data.messages.errors.email;
          this.error.telephone = e.response.data.messages.errors.telephone;
          this.errors = e.response.data.error;
        })
        }
        else{
          this.errors = "Vous devez renseigner tous les champs";
        }
      },
  }
}
</script>
