<template>

    <ChoixCoureur msg="" />

</template>

<script>
// @ is an alias to /src
import ChoixCoureur from '@/components/ChoixCoureur.vue'

export default {
  name: 'ChoixCoureurView',
  components: {
    ChoixCoureur,
  }
}
</script>
