<template>
  <div class="gr-sticky-bottom-container-commande uk-hidden@s" >
    <div class="uk-container">
      <div uk-grid>
        <div class="uk-width-1-3 uk-width-1-6@s uk-flex uk-flex-middle uk-flex-center">
          <button @click="gotoprecedent()" class="uk-flex uk-flex-middle gr-transparent" type="button"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.81836 10.772H20.9998" stroke="#E63323" stroke-width="2" stroke-linecap="square"/>
            <path d="M10.8965 1L1.00045 10.896L10.6485 20.5441" stroke="url(#retourcommande)" stroke-width="2" stroke-linejoin="round"/>
            <defs>
            <radialGradient id="retourcommande" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.7725 10.7721) rotate(45) scale(6.82223 30.9818)">
            <stop stop-color="#831F82"/>
            <stop offset="0.276042" stop-color="#E6007E"/>
            <stop offset="0.53125" stop-color="#E63323"/>
            <stop offset="1" stop-color="#E63323"/>
            </radialGradient>
            </defs>
          </svg></button>
        </div>
        <div class="uk-width-2-3 uk-width-5-6@s uk-flex uk-flex-middle uk-flex-center">
          <a @click="simuclick(1)" v-if="stepCommande==1" class="uk-button uk-width-1-1 cta-sfr-primary">valider la commande</a>
          <a @click="simuclick(2)" v-else-if="stepCommande==2&&!authenticated&&!showformloginstate" id="simuclickregister" class="uk-button uk-width-1-1 cta-sfr-primary  gr-disabled">valider</a>
          <a @click="simuclick(2)" v-else-if="stepCommande==2&&!authenticated&&showformloginstate" class="uk-button uk-width-1-1 cta-sfr-primary">se connecter</a>
          <a @click="simuclick(2)" v-else-if="stepCommande==2&&authenticated" class="uk-button uk-width-1-1 cta-sfr-primary">valider</a>
          <a @click="simuclick(3)" v-else-if="stepCommande==3" id="simuclickpaiement" class="uk-button uk-width-1-1 cta-sfr-primary  gr-disabled">je valide ma commande</a>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapMutations } from 'vuex'
import BoutonPrimary from '@/components/BoutonPrimary.vue'
export default {
  name: 'CommandeStickyBottom',
  components: {
    BoutonPrimary,
  },
  methods:{
    simuclick(step){
      switch(step){
        case 1:
          let elm=document.getElementById('btn-valider-step1');
          elm.click();
          break;
        case 2:
          if(!this.authenticated && !this.showformloginstate){
            let elm2a=document.getElementById('btnValiderRegister');
            elm2a.click();
          }
          else{
            if(!this.authenticated && this.showformloginstate){
              let elm2b=document.getElementById('logincommande');
              elm2b.click();
            }
            else{
              let elm2c=document.getElementById('btn-valider-step2');
              elm2c.click();
            }
          }
          break;
        case 3:
          let elm3=document.getElementById('btnPaiement');
          elm3.click();
          break;
      }
    },
    gotonext(){
      this.$store.commit('gotoStep',{stepCommande:2});
    },
    gotostep(step){
      this.$store.commit('gotoStep',{stepCommande:step});
    },
    showFormLogin(show){
      this.$store.commit('showFormLogin',{showformlogin:show});
    },
    gotoprecedent(){
      if(this.stepCommande==1){
        this.$router.push({ name: 'home'})
      }
      else{
        if(this.stepCommande==2){
          if(this.authenticated){
            this.gotostep(1);
          }
          else{
            if(!this.authenticated&&!this.showformloginstate){
              this.gotostep(1);
            }
            else{
              this.showFormLogin(false);
            }
          }
        }
        else{
          if(this.stepCommande==3){
            this.gotostep(2);
          }
        }
      }
    },
    login() {
      let click_event = new CustomEvent('click');
      let btn_element = document.querySelector('#logincommande');
      btn_element.dispatchEvent(click_event);
    }
  },
  computed: {
    authenticated() {
      return this.$store.state.authenticated
    },
    stepCommande () {
      return this.$store.state.stepCommande
    },
    showformloginstate() {
      return this.$store.state.showformlogin
    }
  },
  props: {
    msg: String
  }
}
</script>
