<template>
  <div id="gr_step_component">
      <div class="circle circle-active-step-1 "><span class="active-number-step-1">1</span></div>
      <div class="divider active-divider-1"></div>
      <a  @click="gotoStep2()"><div class="circle circle-active-step-2 "><span class="active-number-step-2">2</span></div></a>
      <div class="divider active-divider-2"></div>
      <div class="circle  circle-active-step-3 active"><span class="active-number-step-3">3</span></div>
      <div class="divider"></div>
      <div class="circle "><span class="">4</span></div>
  </div>
  <h1 class="gr-texte-black uk-margin-small-bottom">VOS INFORMATIONS</h1>
  <div id="gr_vos_informations" v-if="!authenticated&&!showformloginstate">
    <PanelCommandeStep2Register />
  </div>
  <div id="gr_vos_informations_3" v-if="!authenticated&&showformloginstate">
    <PanelCommandeStep2Login  />
  </div>
  <div id="gr_vos_informations_2"  v-if="authenticated">
    <PanelCommandeStep2Authenticated  />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import PanelCommandeStep2Register from '@/components/PanelCommandeStep2Register.vue'
import PanelCommandeStep2Login from '@/components/PanelCommandeStep2Login.vue'
import PanelCommandeStep2Authenticated from '@/components/PanelCommandeStep2Authenticated.vue'
export default {
  name: 'PanelCommandeStep2',
  components: {
    PanelCommandeStep2Register,
    PanelCommandeStep2Login,
    PanelCommandeStep2Authenticated
  },
  methods:{
    gotoStep1(){
      this.$store.commit('gotoStep',{stepCommande:1});
    },
    gotoStep3(){
      this.$store.commit('gotoStep',{stepCommande:3});
    }
  },
  props: {
    msg: String,
  },
  mounted(){
    
  },
  computed:{
    authenticated() {
      return this.$store.state.authenticated
    },
    showformloginstate() {
      return this.$store.state.showformlogin
    }
  }
}
</script>
