<template>
  <div id="LoginForm" class="uk-width-5-6">
    <h2 class="gr-texte-black">Bienvenue</h2>
    <p class="gr-texte gr-texte-black">Veuillez saisir votre identifiant (adresse mail) et votre mot de passe pour accéder à l'espace coureur SFR Grand Raid.</p>
    <form class="uk-form-stacked">
      <div class="uk-margin">
        <label class="uk-form-label gr-form-label gr-texte-black" for="form-stacked-text">Votre Email<sup>*</sup></label>
        <div class="uk-form-controls">
            <input name="email" class="uk-input gr-form-input" v-model="input.email" type="text" placeholder="Votre email">
        </div>
      </div>
      <div class="uk-margin">
        <label class="uk-form-label gr-form-label gr-texte-black" for="form-stacked-text">Votre mot de passe<sup>*</sup></label>
        <div class="uk-form-controls">
            <input name="password" class="uk-input gr-form-input" v-model="input.password" type="password" placeholder="Votre mot de passe">
        </div>
      </div>
      <div v-if="errors" class="uk-margin uk-alert-danger" uk-alert>
         <a class="uk-alert-close" uk-close></a>
        {{errors}}
      </div>
      <div class="uk-margin uk-margin-medium-top uk-flex uk-flex-column">
        <div>
          <button type="Button" class="uk-button cta-sfr-primary" v-on:click="login()">Se connecter</Button>
        </div>
        <div>
          <a v-on:click.prevent.self="showforgot()" class="gr-link-form">Mot de passe oublié&nbsp;?</a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'LoginForm',
  data() {
      return {
          input: {
              email: "",
              password: ""
          },
          errors:null,
      }
  },
  computed: {
    tokenAuth () {
      return this.$store.state.tokenAuth
    }
  },
  methods: {
      showforgot(){
        this.$store.commit('gotoForgot',{forgotform:true});
      },
      login() {
          if(this.input.email != "" && this.input.password != "") {
              axios.post(this.$store.state.urlws+'login',{email:this.input.email,password:this.input.password})
              .then(response => {
                this.$store.commit('loginto',{isAuthenticate:true,tokenAuth:response.data.token,codesession:response.data.codesession});
                // JSON responses are automatically parsed.
                //this.posts = response.data
              })
              .catch(e => {
                this.errors = e.response.data.error;
              })

          } else {
            this.errors = "Vous devez renseigner tous les champs";
          }
      }
  }
}
</script>
