<template>
  <div class="gr-main-container">
    <section class="mentions">
      <div class="uk-container">
        <h2>Gestion des cookies</h2>
        <ul uk-tab>
          <li><a href="#">Les cookies</a></li>
          <li><a href="#">Les cookies techniques et statistiques</a></li>
          <li><a href="#">Les cookies de personnalisation</a></li>
          <li><a href="#">Les cookies de publicités ciblées</a></li>
          <li><a href="#">Liste des partenaires</a></li>
        </ul>
        <ul class="uk-switcher uk-margin gr-mentions gr-background-grey">
          <li>
            <p>Lors de la navigation sur le site Grandraid.sfr.re, des cookies sont susceptibles d’être déposés et lus pour d'enregistrer les informations relatives à la navigation sur le site (date et heure de la consultation, page consultée, date et heure du clic, lieu du clic...). Cette information permet à Grandraid.sfr.re de personnaliser le site, de faciliter l'accès aux rubriques. Grandraid.sfr.re est également susceptible de traiter vos informations personnelles en vue de personnaliser les contenus publicitaires qui vous sont proposés, vous faire part d'offres promotionnelles ou vous informer sur les nouvelles offres à moins que vous ne vous y opposiez.</p>
            <p>Grandraid.sfr.re vous permet de paramétrer les cookies déposés sur ce site via l’interface accessible directement dans le bandeau Cookie via le&nbsp;<a href="#" class="cookie-param-btn" id="cookie-param-btn">lien&nbsp;</a>.</p>
            <dd><ul>
             	<li><a  href="https://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies">Pour Internet Explorer™</a></li>
             	<li><a  href="https://support.apple.com/kb/PH19214?locale=fr_FR&amp;viewlocale=fr_FR">Pour Safari™</a></li>
             	<li><a href="https://support.google.com/chrome/bin/answer.py?hl=fr&amp;hlrm=en&amp;answer=95647">Pour Chrome™</a></li>
             	<li><a  href="https://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies">Pour Firefox™</a></li>
             	<li><a  href="https://help.opera.com/Windows/10.20/fr/cookies.html">Pour Opera™</a></li>
             	<li><a  href="https://support.apple.com/fr-fr/HT201265">Pour IOS™</a></li>
            </ul></dd>
            <p>Les cookies sont conservés pour une durée maximale de treize mois à compter de leur dépôt sur votre terminal. A l’expiration de ce délai (ou si vous avez supprimé manuellement vos cookies), votre consentement pour le dépôt et/ou la lecture de cookies sera à nouveau requis.</p>
            <p>Grandraid.sfr.re est susceptible d'utiliser votre adresse IP en coopération avec votre fournisseur d'accès à Internet afin de vous identifier, si elle estime nécessaire de faire respecter ses conditions d'utilisation ou de protéger ses services, ses clients ou encore sur demande des autorités judiciaires.</p>
            <!--<h4>Parametrage du suivi:</h4>
            <iframe class="iframe-matomo" src="https://matomo.sfrhd.net/matomo/index.php?module=CoreAdminHome&action=optOut&language=fr&backgroundColor=&fontColor=&fontSize=12px&fontFamily=SFR"></iframe>
            -->
          </li>
            <li>
              <h4>Les cookies techniques</h4>
              <p>Certains cookies sont indispensables au bon fonctionnement du site. Sans eux, vous ne seriez pas en mesure de naviguer sur le site ou ne pourriez pas utiliser certaines de ses fonctions. Ces cookies étant indispensables, ils ne peuvent pas être désactivés.</p>
              <p>Par exemple, lors de votre identification, si vous cochez la case « Rester connecté », votre session restera active pendant 90 jours ou jusqu’à déconnexion de votre part (même après fermeture du navigateur ou arrêt de l’ordinateur). Vous avez ainsi accès à votre compte Espace Client en ligne sans vous identifier à chaque fois.</p>
              <p>Cette opération s’applique uniquement à cet ordinateur. Par conséquent, vous pouvez par exemple rester connecté sur l’ordinateur de votre domicile, mais maintenir une protection complète par mot de passe sur l’ordinateur de votre école ou de votre bureau.</p>
              <p>Attention, si vous utilisez un ordinateur public ou que vous partagez cet ordinateur avec d’autres utilisateurs, nous vous recommandons de ne pas cocher cette case.</p>
              <h4>Cas particulier : Cookie Réseaux sociaux</h4>
              <p>Sur certaines pages de notre site, vous pourrez retrouver des boutons ou modules de réseaux sociaux tiers à Grandraid.sfr.re. Ils vous permettent d'exploiter les fonctionnalités de ces réseaux et en particulier de partager des contenus présents sur Grandraid.sfr.re avec d'autres utilisateurs de ces réseaux. Lorsque vous vous rendez sur une page internet sur laquelle se trouve un de ces boutons ou modules, votre navigateur est susceptible d’envoyer des informations au réseau social qui peut alors associer cette visualisation à votre profil. Des cookies des réseaux sociaux, dont Grandraid.sfr.re n'a pas la maîtrise, peuvent être alors être déposés dans votre navigateur par ces réseaux. Nous vous invitons à consulter les politiques de confidentialité propres à chacun de ces réseaux sociaux, afin de prendre connaissance des finalités d'utilisation des informations de navigation qu’ils peuvent recueillir grâce à ces boutons et modules.</p>
              <h4>Les cookies statistiques</h4>
              <p>Les cookies statistiques nous permettent d’établir des statistiques de fréquentation, d’analyser comment vous utilisez le site WEB et surveiller la performance de notre site.</p>
              <p>Ils sont pour vous un gage de haute qualité grâce à une identification rapide des éventuels problèmes que vous pourriez rencontrer.</p>
              <p>Ils nous permettent également de connaître quelles sont les pages les plus visitées et quels sont les parcours les plus efficaces sur notre site pour vous proposer toujours la meilleure expérience.</p>
              <p>Ces cookies n’enregistrent aucune information capable de vous identifier.</p>
            </li>
            <li>
              <p>Ces cookies permettent de vous faciliter l’accès à nos services et aux rubriques de nos sites, et de personnaliser votre navigation sur nos sites (personnalisation des contenus, des offres et services qui vous sont poussées sur nos sites). Ils nous permettent également de personnaliser les messages que nous vous envoyons.</p>
              <h4>Envoi d’e-mailings personnalisés</h4>
              <p>Grandraid.sfr.re  peut utiliser vos informations personnelles pour vous proposer des offres adaptées, directement sur le site Grandraid.sfr.re  ou lors de communications par e-mails. Dans ce cadre, au clic sur un des liens de ces e-mails, Grandraid.sfr.re  vous permet d’être reconnu sur l’intégralité du site Grandraid.sfr.re  , avec un accès à des offres spécifiques.</p>
            </li>
            <li>
              <p>Ces cookies permettent de vous présenter, sur notre site ou en dehors de notre site, des publicités adaptées à votre navigation et à votre profil client. Ces cookies peuvent être utilisés pour des publicités Grandraid.sfr.re uniquement. Vous avez la possibilité de refuser ces cookies, vous continuerez alors à recevoir des publicités mais ces dernières ne seront pas ciblées en fonction de vos intérêts.</p>
              <h4>Contrôle des cookies de publicité ciblée auprès de Youronlinechoices</h4>
              <p>Le site&nbsp;<a  href="https://www.youronlinechoices.com/fr/">Youronlinechoices</a>, proposé par les professionnels de la publicité digitale regroupés au sein de l'association européenne EDAA (European Digital Advertising Alliance) est géré en France par l'Interactive Advertising Bureau France. Cette plate-forme européenne partagée par de nombreux professionnels de la publicité sur Internet vous offre la possibilité de refuser ou d'accepter les cookies publicitaires utilisés par ces entreprises.</p>
            </li>
            <li>
              <p>Les cookies déposés par Grandraid.sfr.re ou ses partenaires permettent d’acheter, vendre, diffuser et de personnaliser les publicités affichées sur notre site ou en dehors de notre site. Les cookies permettent également de mesurer la performance des publicités affichées et de réaliser des statistiques.</p>
              <h4>Régies publicitaires partenaires</h4>
              <p>Ces partenaires permettent la diffusion de publicités, sur ou en dehors du site SFR, en utilisant des profils publicitaires constitués par SFR et/ou par des tiers. Voici la liste des régies publicitaires partenaires auxquelles nous sommes susceptibles de faire appel :</p>
              <ul>
                <li>Amazon</li>
                <li>Adotmob</li>
                <li>AdYoulike</li>
                <li>Awin</li>
                <li>Bing</li>
                <li>Captify</li>
                <li>Criteo</li>
                <li>Ecselis</li>
                <li>eMottev</li>
                <li>Eperflex</li>
                <li>Facebook</li>
                <li>Google</li>
                <li>Keymantics</li>
                <li>LeBonCoin</li>
                <li>LinkedIn</li>
                <li>Microsoft</li>
                <li>Mindlytix</li>
                <li>Mozoo</li>
                <li>Ogury</li>
                <li>Outbrain</li>
                <li>Quantum</li>
                <li>Reelevant</li>
                <li>NEXT Media Solutions</li>
                <li>Seedtag</li>
                <li>ShowRoomPrivée</li>
                <li>Socialyse</li>
                <li>Taboola</li>
                <li>TEADS (Teads)</li>
                <li>TVTY</li>
                <li>Twitter</li>
                <li>Verizon</li>
              </ul>
              <h4>Statistiques</h4>
              <p>Ces partenaires permettent à SFR d’optimiser ses ventes publicitaires, de lutter contre la fraude publicitaire et d’obtenir des rapports statistiques.</p>
              <ul>
                <li>Bing</li>
                <li>Effinity</li>
                <li>Google</li>
              </ul>
              <h4>Partenaires IAB</h4>
              <p>Ces partenaires peuvent utiliser des cookies pour cibler de la publicité sur les sites SFR : diffusion de publicités, mesure de la performance, constitution de segments publicitaires, correction d’anomalies, lutte contre la fraude publicitaire.</p>
              <ul>
                <li>Marketo</li>
                <li>AB Tasty</li>
                <li>Effiliation</li>
              </ul>
              <h4>Partenaires Search</h4>
              <p>Ces partenaires reçoivent de Grandraid.sfr.re ou fournissent à Grandraid.sfr.re des bases de données cookies permettant d'affiner ses ciblages publicitaires.</p>
              <ul>
                <li>Bing - Microsoft</li>
                <li>Google</li>
              </ul>
              <h4>Autres partenaires</h4>
              <p>Ces partenaires fournissent à SFR une plateforme de gestion de ciblages publicitaires.</p>
              <ul>
                <li>Effinity : affiliation</li>
                <li>Manageo : réconciliation eMail – SIREN</li>
                <li>Cartegie : web service enrichissement de SIREN</li>
                <li>Marketo : comportement web</li>
                <li>Webmecanik : comportement web</li>
                <li>Vertical Mail : affiliation</li>
              </ul>
            </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'GestionCookies',
  props: {
    msg: String
  }
}
</script>
