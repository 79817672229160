<template>
  <div class="uk-grid-small uk-width-3-4" uk-grid>
      <div class="uk-width-1-1 uk-width-1-2@m uk-flex uk-flex-middle"><h2>VOTRE COMPTE CLIENT</h2></div>
      <div class="uk-width-1-1 uk-width-1-2@m"><button @click="showFormLogin()" class="uk-button cta-sfr-black-button">j'ai déjà un compte client&nbsp;!</button></div>
  </div>

  <form  method="post" action="#" class="uk-form-stacked uk-flex uk-flex-top uk-width-1-1 uk-width-3-4@m uk-grid-small" uk-grid>
      <div class="uk-margin uk-width-1-1 uk-width-1-2@m">
          <label class="uk-form-label" for="form-stacked-nom">Nom*</label>
          <div class="uk-form-controls">
              <input class="uk-input uk-width-1-1" v-model="input.nom" name="nom"  id="form-stacked-nom" type="text" placeholder="Votre nom">
              <span class="error-message">{{error.nom}}</span>
          </div>
      </div>
      <div class="uk-margin uk-width-1-1 uk-width-1-2@m">
          <label class="uk-form-label" for="form-stacked-prenom">Prénom*</label>
          <div class="uk-form-controls">
              <input class="uk-input uk-width-1-1" v-model="input.prenom" name="prenom" id="form-stacked-prenom" type="text" placeholder="Votre prénom">
              <span class="error-message">{{error.prenom}}</span>
          </div>
      </div>
      <div class="uk-margin uk-width-1-1 uk-width-1-2@m">
          <label class="uk-form-label" for="form-stacked-email">Email*</label>
          <div class="uk-form-controls">
              <input class="uk-input uk-width-1-1" v-model="input.email" name="email" id="form-stacked-email" type="text" placeholder="Votre email">
              <span class="error-message">{{error.email}}</span>
          </div>
      </div>
      <div class="uk-margin uk-width-1-1 uk-width-1-2@m">
          <label class="uk-form-label" for="form-stacked-telephone">Téléphone*</label>
          <div class="uk-form-controls">
              <input class="uk-input uk-width-1-1" v-model="input.telephone" name="telephone" id="form-stacked-telephone" type="text" placeholder="Votre téléphone">
              <span class="error-message">{{error.telephone}}</span>
          </div>
      </div>
      <div class="uk-margin uk-width-1-1">
          <label class="uk-form-label" for="form-stacked-adresse">Adresse*</label>
          <div class="uk-form-controls">
              <input class="uk-input uk-width-1-1" v-model="input.adresse" name="adresse" id="form-stacked-adresse" type="text" placeholder="Numéro, nom de la rue">
              <span class="error-message">{{error.adresse}}</span>
          </div>
      </div>
      <div class="uk-margin uk-width-1-1 uk-width-1-2@m">
          <label class="uk-form-label" for="form-stacked-codepostal">Code postal*</label>
          <div class="uk-form-controls">
              <input class="uk-input uk-width-1-1" v-model="input.codepostal" name="codepostal" id="form-stacked-codepostal" type="text" placeholder="Votre code postal">
              <span class="error-message">{{error.codepostal}}</span>
          </div>
      </div>
      <div class="uk-margin uk-width-1-1 uk-width-1-2@m">
          <label class="uk-form-label" for="form-stacked-text">Ville*</label>
          <div class="uk-form-controls">
              <input class="uk-input uk-width-1-1" v-model="input.ville" name="ville" id="form-stacked-text" type="text" placeholder="Votre code ville">
              <span class="error-message">{{error.ville}}</span>
          </div>
      </div>
      <div class="uk-margin uk-width-1-1 uk-width-1-2@m">
          <label class="uk-form-label" for="form-stacked-text">Mot de passe*</label>
          <div class="uk-form-controls">
              <input class="uk-input uk-width-1-1" v-model="input.password" name="password" id="form-stacked-text" type="password" placeholder="Votre mot de passe">
              <span class="error-message">{{error.password}}</span>
          </div>
      </div>
      <div class="uk-margin uk-width-1-1 uk-width-1-2@m">
          <label class="uk-form-label" for="form-stacked-confirm_password">Confirmer votre mot de passe*</label>
          <div class="uk-form-controls">
              <input class="uk-input uk-width-1-1" v-model="input.confirm_password" name="confirm_password" id="form-stacked-confirm_password" type="password" placeholder="Votre mot de passe">
              <span class="error-message">{{error.password}}</span>
          </div>
      </div>
      <div class="uk-margin uk-width-1-1">
        <p class="gr-texte-black gr-label-checkbox"><!--<input name="acceptationRGPD" id="acceptationRGPD" class="uk-checkbox" v-model="input.acceptationRGPD"  type="checkbox" v-on:click="changeClassButtonRGPD">-->
          Vous êtes informé(e) que les données à caractère personnel que vous communiquez pour la location de balise, sont uniquement destinées à SFR, en tant que Responsable du Traitement, pour la gestion de la location de balise et son paiement.
          Ces données seront effacées à l'extinction de la finalité du traitement. Vous disposez d'un droit d'accès, de rectification, de modification et de suppression sur vos données à caractère personnel.
          <router-link :to="{ name: 'donneespersonnelles', params: {} }" :class="'gr-link-rgpd'">Cliquez ici pour afficher notre politique relative aux données personnelles.</router-link>
        </p>
      </div>
      <div class="uk-margin uk-width-1-1">
        <p class="gr-texte-black gr-label-checkbox"><input name="acceptationProspectionCommercialeRGPD" id="acceptationProspectionCommercialeRGPD" class="uk-checkbox" v-model="input.acceptationProspectionCommercialeRGPD"  type="checkbox" >
          J'accepte que mes données soient utilisées à des fins de prospection commerciale par SFR
        </p>
      </div>
      <div class="uk-margin uk-width-1-1">
        <div v-if="errors" class="uk-margin uk-alert-danger" uk-alert>
           <a class="uk-alert-close" uk-close></a>
          {{errors}}
        </div>
      </div>
  </form>
  <div class="uk-grid-small uk-width-3-4" uk-grid>
    <div class="uk-flex uk-flex-right uk-width-1-1 uk-margin-large uk-visible@s">
        <button id="btnValiderRegister" class="uk-button cta-sfr-primary "  @click="register()">valider</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapMutations } from 'vuex'
export default {
  name: 'PanelCommandeStep2Register',
  components: {
  },
  data() {
      return {
          input: {
            nom: "",
            prenom: "",
            adresse: "",
            codepostal: "",
            ville: "",
            email: "",
            telephone:"",
            password:"",
            confirm_password:"",

            acceptationRGPD:false,
            acceptationProspectionCommercialeRGPD:false,
          },
          errors:null,
          error:{
            nom: "",
            prenom: null,
            adresse: null,
            codepostal: null,
            ville: null,
            email: null,
            telephone:null,
            password:null,
            confirm_password:null,
            acceptationRGPD:null,
            acceptationProspectionCommercialeRGPD:null
          }
      }
  },
  methods:{
    /*changeClassButtonRGPD(){
      let elem =  document.getElementById("acceptationRGPD");
      let elem0 = document.getElementById("btnValiderRegister");
      let elemsimu = document.getElementById("simuclickregister");
      if(!elem.checked){
        elem0.classList.add("gr-disabled");
        elemsimu.classList.add("gr-disabled");
      }
      else{
        elem0.classList.remove("gr-disabled");
        elemsimu.classList.remove("gr-disabled");
      }
    },*/
    gotoStep1(){
      this.$store.commit('gotoStep',{stepCommande:1});
    },
    gotoStep3(){
      this.$store.commit('gotoStep',{stepCommande:3});
    },
    showFormLogin(){
      this.$store.commit('showFormLogin',{showformlogin:true});
    },
    register(){
          if(this.input.email != "" && this.input.password != "" && this.input.confirm_password != ""
           && this.input.nom != "" && this.input.prenom != "" && this.input.adresse != ""
            && this.input.codepostal != "" && this.input.ville != "" && this.input.telephone != "" ) {
          axios.post(this.$store.state.urlws+'register',
            {
              email:this.input.email,
              password:this.input.password,
              confirm_password:this.input.confirm_password,
              nom:this.input.nom,
              prenom:this.input.prenom,
              adresse:this.input.adresse,
              codepostal:this.input.codepostal,
              ville:this.input.ville,
              telephone:this.input.telephone,
              //acceptationRGPD:this.input.acceptationRGPD,
              acceptationProspectionCommercialeRGPD:this.input.acceptationProspectionCommercialeRGPD,
            }
          )
          .then(response => {
            this.$store.commit('loginto',{isAuthenticate:true,tokenAuth:response.data.token,codesession:response.data.codesession});
            this.$store.commit('gotoStep',{stepCommande:3});
            // JSON responses are automatically parsed.
            //this.posts = response.data

          })
          .catch(e => {
            this.error.nom = e.response.data.messages.errors.nom;
            this.error.prenom = e.response.data.messages.errors.prenom;
            this.error.ville = e.response.data.messages.errors.ville;
            this.error.codepostal = e.response.data.messages.errors.codepostal;
            this.error.adresse = e.response.data.messages.errors.adresse;
            this.error.password = e.response.data.messages.errors.password;
            this.error.confirm_password = e.response.data.messages.errors.confirm_password;
            this.error.email = e.response.data.messages.errors.email;
            this.error.telephone = e.response.data.messages.errors.telephone;
            //this.error.acceptationRGPD = e.response.data.messages.errors.acceptationRGPD;
            this.error.acceptationProspectionCommercialeRGPD = e.response.data.messages.errors.acceptationProspectionCommercialeRGPD;
            this.errors = e.response.data.error;
          })
        }
        else{
          this.errors = "Vous devez renseigner tous les champs";
        }
    },
  },
  props: {
    msg: String
  },
  computed:{
  }
}
</script>
