<template>
  <div id="mon_compte" class="uk-width-1-1">
    <div v-if="messageSuppression">
      <div class="value gr-texte-black">{{messageSuppression}}</div>
    </div>
    <div v-if="!messageSuppression">
      <h2 class="gr-texte-black uk-margin-small-bottom">COMMANDE N°<span>{{infosCommande.numero_commande}}</span></h2>
      <h3 class="gr-texte-black uk-margin-small-top">INFORMATIONS COMMANDE</h3>
      <div uk-grid>
          <div class="uk-width-1-1 uk-width-4-5@s uk-margin-remove" uk-grid>
              <div class="uk-width-1-2">
                  <div class="label">Nom</div>
                  <div class="value gr-texte-black uk-text-break">{{infosCommande.nom}}</div>
              </div>
              <div class="uk-width-1-2">
                  <div class="label">Prénom</div>
                  <div class="value gr-texte-black uk-text-break">{{infosCommande.prenom}}</div>
              </div>
              <div class="uk-width-1-2">
                  <div class="label">Email</div>
                  <div class="value gr-texte-black uk-text-break">{{infosCommande.email}}</div>
              </div>
              <div class="uk-width-1-2">
                  <div class="label">Téléphone</div>
                  <div class="value gr-texte-black uk-text-break">{{infosCommande.telephone}}</div>
              </div>
              <div class="uk-width-1-1">
                  <div class="label">Adresse</div>
                  <div class="value gr-texte-black uk-text-break">{{infosCommande.adresse}}</div>
              </div>
              <div class="uk-width-1-2">
                  <div class="label">Code Postal</div>
                  <div class="value gr-texte-black uk-text-break">{{infosCommande.codepostal}}</div>
              </div>
              <div class="uk-width-1-2">
                  <div class="label">Ville</div>
                  <div class="value gr-texte-black uk-text-break">{{infosCommande.ville}}</div>
              </div>
              <div class="uk-width-1-1">
                  <div class="label">Nature</div>
                  <div class="value gr-texte-black uk-text-break">Location d'une balise GPS CAPTURS</div>
              </div>
              <div class="uk-width-1-1">
                  <div class="label">Etat</div>
                  <div class="value gr-texte-black uk-text-break">{{infosCommande.etat}}</div>
              </div>
          </div>
          <div class="uk-width-1-5 uk-hidden@s"></div>
      </div>
      <div v-if="infosCommande.etat=='En attente de paiement'||infosCommande.etat=='Temporaire'" class="uk-flex uk-flex-column uk-flex-top">
          <button @click="paiement" class="uk-button cta-sfr-primary uk-margin-small-top uk-margin-small-bottom">Procéder au paiement</button>
          <button @click="annulationCommande" class="uk-button  cta-sfr-light uk-margin-small-top uk-margin-small-bottom">Annuler la commande</button>
          <div v-if="errors" class="uk-alert-danger" uk-alert>
            {{errors}}
          </div>
      </div>
      <div v-if="infosCommande.factureExist" class="uk-flex uk-flex-column uk-flex-top">
          <button @click="downloadFacture" class="uk-button cta-sfr-primary uk-margin-small-top uk-margin-small-bottom">Télécharger ma facture</button>
      </div>
      <h3 v-if="infosCommande.etat!=='En attente de paiement'" class="gr-texte-black uk-margin-small-top uk-margin-medium-top">INFORMATIONS DE LOCATION</h3>

      <div v-if="infosCommande.etat!=='En attente de paiement'" uk-grid>
          <div class="uk-width-1-1 uk-width-5-6@s uk-margin-remove" uk-grid>
            <div class="uk-width-1-2">
                <div class="label">Balise Liée</div>
                <div class="value gr-texte-black">Non livrée</div>
            </div>
            <div class="uk-width-1-2">
                <div class="label">
                    Restitution
                    <svg id="information_commande_icon_info" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.299805 6.99992C0.299805 3.31992 3.30022 0.333252 6.99717 0.333252C10.6941 0.333252 13.6945 3.31992 13.6945 6.99992C13.6945 10.6799 10.6941 13.6666 6.99717 13.6666C3.30022 13.6666 0.299805 10.6799 0.299805 6.99992ZM7.6669 6.33325V10.3333H6.32743V6.33325H7.6669ZM6.99717 12.3333C4.04363 12.3333 1.63928 9.93992 1.63928 6.99992C1.63928 4.05992 4.04363 1.66659 6.99717 1.66659C9.95071 1.66659 12.3551 4.05992 12.3551 6.99992C12.3551 9.93992 9.95071 12.3333 6.99717 12.3333ZM7.6669 3.66659V4.99992H6.32743V3.66659H7.6669Z" fill="#FF0000"/>
                </svg>
                </div>
                <div class="value gr-texte-black">N/A</div>
            </div>
          </div>
        </div>
        <h4 v-if="infosCommande.etat!=='En attente de paiement'" class="gr-texte-black uk-margin-small-top uk-margin-medium-top uk-text-capitalize">Informations Paiement</h4>
        <div v-if="infosCommande.etat!=='En attente de paiement'" uk-grid>
          <div class="uk-width-1-1 uk-width-5-6@s uk-margin-remove" uk-grid>
              <div class="uk-width-1-1">
                  <div class="label">Date Paiement</div>
                  <div class="value gr-texte-black">{{infosCommande.datePaiement}}</div>
              </div>
              <div class="uk-width-1-1">
                  <div class="label">Transactions ID :</div>
                  <div class="value gr-texte-black" v-for="transaction in this.infosCommande.transactionInfos">{{transaction.transactionId}} | {{(transaction.amount/100)}}€ | {{transaction.dateCreation}} | {{transaction.etat}}</div>
              </div>
          </div>
          <div class="uk-width-1-5 uk-hidden@s"></div>
      </div>
    </div>
  </div>

  <div class="uk-hidden">
    <form id="paiementform" method="POST" action="">
      <input type="hidden" name="codesession" :value="codesession" />
      <input type="hidden" name="coid" id="coid" value="" />
    </form>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'DetailsCommande',
  data() {
    return {
      infosCommande:{
        nom:'',
        prenom:'',
        adresse:'',
        codepostal:'',
        ville:'',
        email:'',
        telephone:'',
        numero_commande:'',
        etat:'',
        factureExist:0,
        commandeId:'',
        datePaiement:'',
        transactionInfos:[],
      },
      messageSuppression:'',
      errors:'',
    }
  },
  computed: {
      tokenAuth () {
        return this.$store.state.tokenAuth
      },
      authenticated() {
        return this.$store.state.authenticated
      },
      codesession() {
        return this.$store.state.codesession
      },
      commandeEnCoursMC() {
        return this.$store.state.commandeEnCoursMC
      },
  },
  methods: {
    annulationCommande(){
      this.errors='';
      if(this.authenticated&&this.tokenAuth!=""&&this.codesession!=""&&this.infosCommande.commandeId!=""){
        console.log("commandeId:",this.infosCommande.commandeId);
        axios.post(this.$store.state.urlws+'annulerCommande',{'codesession': this.codesession,'commandeId':this.infosCommande.commandeId},{headers:{Authorization:'Bearer '+this.tokenAuth+''}})
        .then(response => {
          if(response.data.status=='success'){
            this.messageSuppression=response.data.message;
          }
          else{
            //console.log('ici');
            this.errors=response.data.erreur;
            //console.log("this.errors:",this.errors);
          }
          //console.log('this:',response);
        })
        .catch(e => {
          //this.$store.commit('deconnexion',{});

          //this.$router.push({ name: 'commande' });
        })
      }
    },
    downloadFacture(){
      this.errors='';
      let formid=document.getElementById('paiementform');
      formid.action=this.$store.state.urlfacturepage+'do';
      let coid=this.infosCommande.commandeId;
      let coidinput=document.getElementById('coid');
      coidinput.value=coid;
      formid.submit();
    },
    paiement(){
      this.errors='';
      let formid=document.getElementById('paiementform');
      formid.action=this.$store.state.urlpaiementpage+'do';
      let coid=this.infosCommande.commandeId;
      let coidinput=document.getElementById('coid');
      coidinput.value=coid;
      formid.submit();
      /*axios.post(this.$store.state.urlws+'payerMaCommande',{'codesession': this.codesession,'commandeId':this.infosCommande.commandeId},{headers:{Authorization:'Bearer '+this.tokenAuth+''}})
      .then(response => {
        console.log("response:",response);
        //this.$router.push({ path: '/commandeMessage/'+response.data.idCommande });
        //let coid=response.data.idCommande;
        //let coidinput=document.getElementById('coid');
        //coidinput.value=coid;
        //formid.submit();
        // JSON responses are automatically parsed.
        //this.posts = response.data

      })
      .catch(e => {
        if(e.code=="ERR_NETWORK" && !e.response.data){
          this.$store.commit('deconnexion');
        }
        else{
          let message = "Une erreur est survenue.";
          if(e.response){
            if(e.response.data){
              if(e.response.data.action=="deconnexion"){
                this.$store.commit('deconnexion');
              }
              else{
                if(e.response.data.messages){
                  if(e.response.data.messages.errors){
                    if(e.response.data.messages.errors.quantite){
                      message+=e.response.data.messages.errors.quantite;
                    }
                  }
                }
              }
            }
            this.errors=message;
          }
        }

      })*/
    },
  },
  mounted(){
    let f='';
    if(this.authenticated&&this.tokenAuth!=""){
      axios.post(this.$store.state.urlws+'detailCommande',{'codesession': this.codesession,'commandeId':this.commandeEnCoursMC},{headers:{Authorization:'Bearer '+this.tokenAuth+''}})
      .then(response => {
        this.infosCommande.numero_commande=response.data.detailCommande.infoC.numero_commande;
        if(response.data.detailCommande.infoC.etat=="temporaire"){
          //this.infosCommande.etat="Réservé";
          this.infosCommande.etat="En attente de paiement";
        }
        else{
          this.infosCommande.etat=response.data.detailCommande.infoC.etat;
        }
        this.infosCommande.commandeId=response.data.detailCommande.infoC.id;
        this.infosCommande.nom=response.data.detailCommande.infoC.nomContact;
        this.infosCommande.prenom=response.data.detailCommande.infoC.prenomContact;
        this.infosCommande.adresse=response.data.detailCommande.infoC.adresseLivraison;
        this.infosCommande.codepostal=response.data.detailCommande.infoC.codePostalLivraison;
        this.infosCommande.ville=response.data.detailCommande.infoC.villeLivraison;
        this.infosCommande.email=response.data.detailCommande.infoC.email;
        this.infosCommande.telephone=response.data.detailCommande.infoC.telephone;
        this.infosCommande.factureExist=(response.data.detailCommande.infoC.factureExist==="1"?1:0);
        this.infosCommande.datePaiement=response.data.detailCommande.infoC.datePaiement;
        this.infosCommande.transactionInfos=response.data.detailCommande.transactions;
        console.log("infosCommande:",this.infosCommande);
        console.log('response.data:',response.data);
      })
      .catch(e => {
        //this.$store.commit('deconnexion',{});

        //this.$router.push({ name: 'commande' });
      })
    }
  },
}
</script>
