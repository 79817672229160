<template>
  <div class="gr-main-container">
    <section class="gr-pleine-hauteur gr-degrade-lavande uk-flex uk-flex-column uk-flex-middle">
      live
    </section>
  </div>
</template>

<script>
export default {
  name: 'HomeLive',
  props: {
    msg: String
  }
}
</script>
