<template>
  <div class="gr-main-container">
    <section class="mentions">
      <div class="uk-container">
        <h2>Comment ça fonctionne ?</h2>
       
        <ul uk-tab>
          <li><a href="#">Comment ça marche la balise ?</a></li>
          <li><a href="#">Comment récupérer ma balise ?</a></li>
          <li><a href="#">Comment restituer ma balise ?</a></li>
          <li><a href="#">Que se passe-t-il si je ne restitue pas ma balise ?</a></li>
          <li><a href="#">Comment suivre la course ?</a></li>
        </ul>
        <ul class="uk-switcher uk-margin gr-mentions gr-background-grey">
            <li>
              <h3>Comment ça marche la balise ?</h3>
              <p>La balise vous est fournie le jour de la remise des dossards à la Ravine Blanche à Saint Pierre, le mercredi 16 octobre 2024 sur le stand de SFR.</p>
              <p>Elle vous est remise chargée et éteinte. Elle est déjà associée à votre dossard. La seule chose à faire est de l’allumer avant le départ en appuyant sur le bouton rouge central. Le voyant en haut à gauche s’éclaire alors en vert.</p>
              <p>Dès lors qu’elle est allumée, elle émet un signal GPS toutes les 3 minutes. Le voyant bleu en bas à gauche s’éclaire lors de l’émission du signal.</p>
              <p><img src="@/assets/img/comment-ca-fonctionne.jpg" alt="boitier" class="img-faq" /></p>
              <p>Pour ne pas perturber le signal GPS, placez votre balise sur le haut de votre sac et évitez de la placer proche de votre couverture de survie ou de tout objet métallique.</p>
              <p>Le suivi de votre position Live est disponible sur le site grandraid.sfr.re et sera actif dès que le départ de votre course aura été donné.</p>
              <p>Pour éteindre la balise, il suffit d’appuyer de façon prolongée pendant 5 secondes sur le bouton central rouge. Le voyant en haut à droite s’éclaire alors en rouge.</p>
              <p>La balise dispose d’une autonomie d’un mois.</p>
            </li>
            <li>
              <h3>Comment récupérer ma balise ?</h3>
              <p>Seuls les coureurs ayant réservé leur balise en complétant les démarches sur le. site grandraid.sfr.re <strong>avant le 01/10/2024</strong> pourront bénéficier de leur balise gratuitement (dans la limite des stocks disponibles).</p>
              <p>La balise sera <strong>à récupérer à la Ravine Blanche à Saint Pierre</strong>, lors de la remise des dossards le <strong>mercredi 16 octobre 2024 sur le stand de SFR</strong>. Elle vous sera remise sur présentation de votre dossard.</p>
            </li>
            <li>
              <h3>Comment restituer ma balise ?</h3>
              <p>2 options s’offrent à vous : </p>
              <ul>
                <li>A la fin de votre course, au stade de la Redoute déposez-là dans la boîte présente sur la ligne d’arrivée ou celle sur le stand de SFR.</li>
                <li>Ou, vous avez jusqu’au 26 octobre 2024 pour vous rendre dans la boutique SFR la plus proche de chez vous.</li>
              </ul>
              <p>Une balise qui n’est pas restituée sera prélevée automatiquement de 90€ et ne sera plus de la responsabilité de SFR.</p>
            </li>
            <li>
              <h3>Que se passe-t-il si je ne restitue pas ma balise ?</h3>
              <p>Vous serez prélevez automatiquement d’un montant de 90€ et la balise vous appartiendra et ne sera plus sous la responsabilité de SFR.</p>
            </li>
            <li>
              <h3>Comment suivre la course ?</h3>
              <p>Le suivi Live est disponible sur le site grandraid.sfr.re et sera actif dès que le départ de chaque course aura été donné.</p>
              <h4>Vous connaissez un coureur : </h4>
              <ul>
                <li>Rendez-vous sur : grandrais.sfr.re</li>
                <li>Saisissez dans la barre de recherche : le n° de dossard ou le nom ou le prénom du coureur dont vous voulez suivre la course</li>
                <li>Suivez la course en temps réel sur la carte</li>
                <li><img src="@/assets/img/carte-suivi.jpg" alt="carte suivi live" class="img-faq" /></li>
              </ul>
              <h4>Vous êtes curieux ?</h4>
              <ul>
                <li>Rendez-vous sur : grandrais.sfr.re</li>
                <li>Suivez les courses en temps réels sur la carte.</li>
                <li><img src="@/assets/img/carte-suivi.jpg" alt="carte suivi live" class="img-faq" /></li>
              </ul>
            </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Faq',
  props: {
    msg: String
  }
}
</script>
