<template>
  <div class="uk-container" id="tostiky-panel">
    <div class="uk-width-1-1 uk-grid-collapse" uk-grid>
      <div id="gr_votre_commande" v-if="stepCommande==0" class="uk-width-1-1 uk-width-1-2@s uk-width-2-3@m uk-margin-small-top">
        <PanelCommandeStep0 />
      </div>
      <div id="gr_votre_commande" v-if="stepCommande==1" class="uk-width-1-1 uk-width-1-2@s uk-width-2-3@m uk-margin-small-top">
        <PanelCommandeStep1 />
      </div>
      <div id="gr_vos_informations" v-if="stepCommande==2" class="uk-width-1-1 uk-width-1-2@s uk-width-2-3@m uk-margin-small-top">
        <PanelCommandeStep2 />
      </div>
      <div id="finalisation_commande" v-if="stepCommande==3" class="uk-width-1-1 uk-width-1-2@s uk-width-2-3@m uk-margin-small-top">
        <PanelCommandeStep3 />
      </div>
      <div id="gr_details_commande" class="uk-width-1-1 uk-width-1-2@s uk-width-1-3@m uk-visible@s">
        <PanelRecapitulatif />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import PanelCommandeStep0 from '@/components/PanelCommandeStep0.vue'
import PanelCommandeStep1 from '@/components/PanelCommandeStep1.vue'
import PanelCommandeStep2 from '@/components/PanelCommandeStep2.vue'
import PanelCommandeStep3 from '@/components/PanelCommandeStep3.vue'
import PanelRecapitulatif from '@/components/PanelRecapitulatif.vue'
export default {
  name: 'Commande',
  components: {
    PanelCommandeStep0,
    PanelCommandeStep1,
    PanelCommandeStep2,
    PanelCommandeStep3,
    PanelRecapitulatif,
  },
  beforeMount(){
    this.$store.commit('gotoStep',{stepCommande:0});
    this.$store.commit('gotoStep',{stepCommande:0})
  },
  computed: {
    stepCommande () {
      return this.$store.state.stepCommande
    }
  },
  props: {
    msg: String
  }
}
</script>
