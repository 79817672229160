<template>
  <div id="panel-infos" class="panel-infos" uk-offcanvas="flip:true;overlay: true;">
    <div class="uk-offcanvas-bar gr-offcanvas-50">
      <div class="uk-margin-medium" uk-grid>
        <div class="uk-margin-remove uk-flex uk-align-middle" >
          <button class="uk-offcanvas-close uk-flex uk-flex-middle gr-offcanvas-close" type="button"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.81836 10.772H20.9998" stroke="#E63323" stroke-width="2" stroke-linecap="square"/>
            <path d="M10.8965 1L1.00045 10.896L10.6485 20.5441" stroke="url(#retourcompte)" stroke-width="2" stroke-linejoin="round"/>
            <defs>
            <radialGradient id="retourcompte" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.7725 10.7721) rotate(45) scale(6.82223 30.9818)">
            <stop stop-color="#831F82"/>
            <stop offset="0.276042" stop-color="#E6007E"/>
            <stop offset="0.53125" stop-color="#E63323"/>
            <stop offset="1" stop-color="#E63323"/>
            </radialGradient>
            </defs>
          </svg><div v-if="!authenticated">Retour</div></button>
        </div>
        <div v-if="authenticated" class="uk-margin-remove uk-flex uk-align-middle">
          <div class="uk-button-group gr-group-button" v-if="authenticated && (stepMC!='mescommandes' && stepMC!='detailsCommande')">
             <button  @click="changeStepMC('mesinformations')" class="uk-button uk-button-secondary cta-sfr-white-button">Mes informations</button>
             <button  @click="changeStepMC('mescommandes')" class="uk-button uk-button-secondary cta-sfr-black-button">Mes commandes</button>
           </div>
           <div class="uk-button-group gr-group-button" v-if="authenticated && (stepMC=='mescommandes' || stepMC=='detailsCommande')">
              <button  @click="changeStepMC('mesinformations')" class="uk-button uk-button-secondary cta-sfr-black-button">Mes informations</button>
              <button  @click="changeStepMC('mescommandes')" class="uk-button uk-button-secondary cta-sfr-white-button">Mes commandes</button>
            </div>
        </div>
        <div v-if="authenticated" class="uk-margin-remove uk-flex uk-align-middle"><a class="uk-flex uk-align-middle" @click="deconnexion()"><svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.23301 4.05303C4.89223 4.72704 2.87795 6.23226 1.5683 8.28612C0.258656 10.34 -0.256297 12.8013 0.120115 15.2079C0.496527 17.6145 1.73842 19.801 3.61265 21.3569C5.48689 22.9128 7.86458 23.731 10.2994 23.6581C12.7342 23.5852 15.0586 22.6261 16.8364 20.9608C18.6141 19.2956 19.7229 17.0387 19.9547 14.6138C20.1864 12.189 19.525 9.76295 18.0948 7.79115C16.6646 5.81934 14.5639 4.43737 12.1869 3.90466L11.7319 5.93491C13.6143 6.35678 15.278 7.45122 16.4106 9.01277C17.5432 10.5743 18.067 12.4956 17.8835 14.4159C17.7 16.3362 16.8219 18.1236 15.414 19.4423C14.0061 20.7611 12.1653 21.5207 10.2371 21.5784C8.30887 21.6362 6.42589 20.9882 4.94161 19.756C3.45733 18.5238 2.47383 16.7923 2.17573 14.8864C1.87764 12.9805 2.28545 11.0313 3.32261 9.40476C4.35977 7.77822 5.95496 6.58618 7.80871 6.05241L7.23301 4.05303Z" fill="url(#paint0_angular_660_16801)"/>
          <path d="M9.95801 1.33716V10.1651" stroke="#E30613" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
          <defs>
          <radialGradient id="paint0_angular_660_16801" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10 13.6626) rotate(-90) scale(10 44.2752)">
          <stop stop-color="#831F82"/>
          <stop offset="0.276042" stop-color="#E6007E"/>
          <stop offset="0.53125" stop-color="#E63323"/>
          <stop offset="1" stop-color="#E63323"/>
          </radialGradient>
          </defs>
        </svg></a>
        </div>
      </div>
      <LoginForm  v-if="!authenticated&&!forgotform"  msg="" />
      <ForgotForm  v-if="!authenticated&&forgotform"  msg="" />
      <MonCompte  v-if="authenticated && stepMC=='mesinformations'"  msg="" />
      <MonCompteMod  v-if="authenticated && stepMC=='mesinformationsform'"  msg="" />
      <MonCompteMdpMod  v-if="authenticated && stepMC=='passwordform'"  msg="" />
      <MesCommandes  v-if="authenticated && stepMC=='mescommandes'"  msg="" />
      <DetailsCommande  v-if="authenticated && stepMC=='detailsCommande'"  msg="" />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import LoginForm from '@/components/LoginForm.vue'
import ForgotForm from '@/components/ForgotForm.vue'
import MonCompte from '@/components/MonCompte.vue'
import MonCompteMod from '@/components/MonCompteMod.vue'
import MonCompteMdpMod from '@/components/MonCompteMdpMod.vue'
import MesCommandes from '@/components/MesCommandes.vue'
import DetailsCommande from '@/components/DetailsCommande.vue'
export default {
  name: 'PanelCompte',
  components: {
    LoginForm,
    ForgotForm,
    MonCompte,
    MonCompteMod,
    MonCompteMdpMod,
    MesCommandes,
    DetailsCommande,
  },
  methods:{
    changeStepMC(label){
      this.$store.commit('gotoStepMC',{stepMC:label});
    },
    gotoForgot(){
      this.$store.commit('gotoForgot');
    },
    deconnexion(){
      this.$store.commit('deconnexion',{});
    }
  },
  computed: {
    authenticated() {
      return this.$store.state.authenticated
    },
    forgotform() {
      return this.$store.state.forgotform
    },
    stepMC() {
      return this.$store.state.stepMC
    }
  },
  props: {
    msg: String,
  }
}
</script>
