<template>
  <div id="mes_commandes" class="uk-width-1-1">
      <h2 class="gr-texte-black uk-margin-small-bottom">MES COMMANDES</h2>
      <h3 class="gr-texte-black uk-margin-small-top">ETAT DE LA COMMANDE</h3>
      <div uk-grid>
          <div class="uk-width-1-1  uk-margin-remove" >
            <table class="uk-table uk-table-divider gr-valign-center">
              <thead>
                  <tr>
                      <th class="gr-texte-black">N°Commande</th>
                      <th class="gr-texte-black">Etat</th>
                      <th class="gr-texte-black">Qté</th>
                      <th class="gr-texte-black">Action</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="commande in this.listeCommandes">
                      <td class="gr-texte-black">{{commande.numero_commande}}</td>
                      <td class="gr-texte-black">{{commande.etat}}</td>
                      <td class="gr-texte-black">{{commande.qte}}</td>
                      <td class="gr-texte-black"><button @click="changeStepMC('detailsCommande',commande.id)" class="uk-button cta-sfr-light cta-sfr-small">Voir les détails</button></td>
                  </tr>
              </tbody>
          </table>
        </div>
        <div class="uk-width-1-5 uk-hidden@s"></div>
      </div>

      <!--<div class="uk-flex uk-flex-column uk-flex-top">
          <button class="uk-button cta-sfr-primary uk-margin-large-top uk-margin-small-bottom">CONTINUER MA COMMANDE</button>
          <button @click="changeStepMC('detailsCommande')" class="uk-button cta-sfr-light">Voir les détails de la commande</button>
      </div>-->
  </div>
</template>

<script>
import axios from 'axios';
import { mapMutations } from 'vuex'
export default {
  name: 'MesCommandes',
  data() {
    return {
      listeCommandes:null
    }
  },
  mounted(){
    //console.log("mounted");
    if(this.authenticated&&this.tokenAuth!=""){
      //console.log("launch");
      //var vm = this;
      axios.post(this.$store.state.urlws+'mescommandes',{'codesession': this.codesession},{headers:{Authorization:'Bearer '+this.tokenAuth+''}})
      .then(response => {
        //console.log("response.data:",response.data);
        this.listeCommandes=response.data.listeCommandes;
      })
      .catch(e => {
        if(e.code=="ERR_NETWORK" && !e.response.data){
          this.$store.commit('deconnexion');
        }
        else{
          let message = "Une erreur est survenue.";
          if(e.response){
            if(e.response.data){
              if(e.response.data.action=="deconnexion"){
                this.$store.commit('deconnexion');
              }
            }
            this.errors=message;
          }
        }

      })
    }
    else{
      this.$store.commit('deconnexion');
    }
  },
  computed: {
    tokenAuth () {
      return this.$store.state.tokenAuth
    },
    authenticated() {
      return this.$store.state.authenticated
    },
    codesession() {
      return this.$store.state.codesession
    },
  },
  methods:{
    changeStepMC(label,commandeId){
      //console.log("commandeId:",commandeId);
      this.$store.commit('gotoStepMC',{stepMC:label,commandeEnCoursMC:commandeId});
    },
  },
}
</script>
