<template>
  <div id="LoginForm" class="uk-width-5-6">
    <h2 class="gr-texte-black">Vous avez oublié votre mot de passe</h2>
    <p class="gr-texte gr-texte-black">Veuillez saisir votre identifiant (adresse mail) et vous recevrez un email contenant le lien vous permettant de modifier votre mot de passe.</p>
    <form class="uk-form-stacked">
      <div class="uk-margin">
        <label class="uk-form-label gr-form-label gr-texte-black" for="form-stacked-text">Votre Email<sup>*</sup></label>
        <div class="uk-form-controls">
            <input name="email" class="uk-input gr-form-input" v-model="input.email" type="text" placeholder="Votre email">
        </div>
      </div>
      <div v-if="errors" class="uk-margin uk-alert-danger" uk-alert>
         <a class="uk-alert-close" uk-close></a>
        {{errors}}
      </div>
      <div class="uk-margin uk-margin-medium-top uk-flex uk-flex-column">
        <div>
          <button type="Button" class="uk-button cta-sfr-primary" v-on:click="forgot()">Envoyer</Button>
        </div>
        <div>
          <a href="" class="gr-link-form">Retour à mon compte</a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'ForgotForm',
  data() {
      return {
          input: {
              email: "",
          },
          errors:null,
      }
  },
  computed: {
    tokenAuth () {
      return this.$store.state.tokenAuth
    }
  },
  methods: {
      forgot() {
          if(this.input.email != "" ) {
              axios.post(this.$store.state.urlws+'forgotmdp',{email:this.input.email})
              .then(response => {
                //this.$store.commit('loginto',{isAuthenticate:true,tokenAuth:response.data.token,codesession:response.data.codesession});
                // JSON responses are automatically parsed.
                //this.posts = response.data
                this.errors="Un email vous a été envoyé";
              })
              .catch(e => {
                //this.errors = e.response.data.error;
              })

          } else {
            this.errors = "Vous devez renseigner tous les champs";
          }
      }
  }
}
</script>
