<template>

    <HomeMarketing msg="" />
  
</template>

<script>
// @ is an alias to /src
import HomeMarketing from '@/components/HomeMarketing.vue'

export default {
  name: 'HomeView',
  components: {
    HomeMarketing,
  }
}
</script>
