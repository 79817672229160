<template>

    <Faq msg="" />

</template>

<script>
// @ is an alias to /src
import Faq from '@/components/Faq.vue'

export default {
  name: 'FaqView',
  components: {
    Faq,
  }
}
</script>
