import { createStore } from 'vuex'
import axios from 'axios';
function storageAvailable(type) {
    try {
        var storage = window[type],
            x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch(e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage.length !== 0;
    }
}
var burl = window.location.origin;
var sessionStore = false;
var initialData = {
  openModal: false,
  authenticated:false,
  tokenAuth:"",
  codesession:"",
  showformlogin:false,
  stepCommande:1,
  stepMC:"mesinformations",
  productPrice:3,
  panier:{
    nb:1
  }
}
if(storageAvailable('sessionStorage')){
  sessionStore=window.sessionStorage;
}
function sessionGet(info){
  if(sessionStore){
    let valueinfo = sessionStore.getItem(info);
    if(info=='authenticated' || info=='openModal' || info=='showformlogin'){
      if(valueinfo === 'true'){
        valueinfo=true;
      }
      else{
        valueinfo=false;
      }
    }
    else{
      if(info=='stepCommande' || info=='paniernb'){
        valueinfo=parseInt(valueinfo);
      }
    }
    return valueinfo;
  }
  else{
    return false;
  }
}
function sessionPut(info,value){
  if(sessionStore){
    sessionStore.setItem(info,value);
    return true;
  }
  else{
    return false;
  }
}
function saveToSessionStore(state){
  sessionPut('authenticated',state.authenticated);
  sessionPut('tokenAuth',state.tokenAuth);
  sessionPut('codesession',state.codesession);
  sessionPut('openModal',state.openModal);
  sessionPut('showformlogin',state.showformlogin);
  sessionPut('stepCommande',state.stepCommande);
  sessionPut('stepMC',state.stepMC);
  sessionPut('paniernb',state.panier.nb);
}
export default createStore({
  state: {
    //urlws: "https://gr2022.digital1to1.fr/wsgr/index.php?/api/", //SITE de DEV
    //urlpaiementpage: "https://gr2022.digital1to1.fr/wsgr/index.php?/paiement/", //SITE de DEV
    //urlfacturepage: "https://gr2022.digital1to1.fr/wsgr/index.php?/maFacture/", //SITE de DEV
    //urlws: "https://grc.sfrhd.net/wsgr/index.php?/api/", //SITE de TEST
    //urlpaiementpage: "https://grc.sfrhd.net/wsgr/index.php?/paiement/", //SITE de TEST
    //urlfacturepage: "https://grc.sfrhd.net/wsgr/index.php?/maFacture/", //SITE de TEST

    //urlws: "https://grc01.sfrhd.net/wsgr/index.php?/api/", //SITE de TEST2
    //urlpaiementpage: "https://grc01.sfrhd.net/wsgr/index.php?/paiement/", //SITE de TEST2
    //urlfacturepage: "https://grc01.sfrhd.net/wsgr/index.php?/maFacture/", //SITE de TEST2

    //urlws: "https://grandraid.sfr.re/wsgr/index.php/api/", //SITE de PROD
    //urlpaiementpage: "https://grandraid.sfr.re/wsgr/index.php/paiement/", //SITE de PROD
    //urlfacturepage: "https://grandraid.sfr.re/wsgr/index.php/maFacture/", //SITE de PROD
    urlws: burl+"/wsgr/index.php/api/", //SITE de PROD
    urlpaiementpage: burl+"/wsgr/index.php/paiement/", //SITE de PROD
    urlfacturepage: burl+"/wsgr/index.php/maFacture/", //SITE de PROD

    productPrice:0,
    isDiagonale: false, // Nouvel état pour savoir si le coureur est de la Diagonale des Fous
    openModal: false,
    authenticated:sessionGet('authenticated'),
    tokenAuth:(!sessionGet('tokenAuth')?initialData.tokenAuth:sessionGet('tokenAuth')),
    codesession:(!sessionGet('codesession')?initialData.codesession:sessionGet('codesession')),
    showformlogin:sessionGet('showformlogin'),
    stepCommande:(!sessionGet('stepCommande')?initialData.stepCommande:sessionGet('stepCommande')),
    forgotform:false,
    stepMC:(!sessionGet('stepMC')?initialData.stepMC:sessionGet('stepMC')),
    commandeEnCoursMC:{
      idCommande:null,
    },
    panier:{
      nb:(!sessionGet('paniernb')?initialData.panier.nb:sessionGet('paniernb')),
    },
    commandeEnCours:{
      nb:0,
      idCommande:null,
      refCommande:null,
    }
  },
  getters: {
  },
  mutations: {
    addProduct(state,payload){
      state.panier.nb++;
      saveToSessionStore(state);
    },
    removeProduct(state,payload){
      if(state.panier.nb>1){
        state.panier.nb--;
        saveToSessionStore(state);
      }
    },
    setProductPrice(state, newPrice) {
      state.productPrice = newPrice;
      saveToSessionStore(state); // Si vous voulez sauvegarder dans la session
    },
    showFormLogin(state,payload){
      state.showformlogin=payload.showformlogin;
      saveToSessionStore(state);
    },
    gotoStepMC(state,payload){
      state.stepMC=payload.stepMC;
      state.commandeEnCoursMC=payload.commandeEnCoursMC;
      saveToSessionStore(state);
    },
    gotoForgot(state,payload){
      state.forgotform=payload.forgotform;
    },
    gotoStep(state,payload){
      state.stepCommande=payload.stepCommande;
      saveToSessionStore(state);
    },
    loginto(state,payload){
      state.authenticated = payload.isAuthenticate;
      state.tokenAuth = payload.tokenAuth;
      state.codesession = payload.codesession;
      saveToSessionStore(state);
    },
    deconnexion(state){
      state.authenticated = initialData.authenticated;
      state.tokenAuth = initialData.tokenAuth;
      state.codesession = initialData.codesession;
      state.showformlogin = initialData.showformlogin;
      state.stepMC = initialData.stepMC;
      if(state.stepCommande>=2){
        state.stepCommande = 2;
      }
      state.stepMC = initialData.stepMC;
      saveToSessionStore(state);
    }
  },
  actions: {
    loginto(context,payload){
      return new Promise((resolve,reject) => {
        if(payload.email != "" && payload.password != "") {

        }
        else{
          reject({error:"A username and password must be present"});
        }
      })
    }
  },
  modules: {
  }
})
