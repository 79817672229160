<template>

    <ChoixCoureurFin msg="" />

</template>

<script>
// @ is an alias to /src
import ChoixCoureurFin from '@/components/ChoixCoureurFin.vue'

export default {
  name: 'ChoixCoureurFinView',
  components: {
    ChoixCoureurFin,
  }
}
</script>
