import { createRouter, createWebHashHistory } from 'vue-router'
import HomeMarketingHeader from '@/components/HomeMarketingHeader.vue'
import HomeLiveHeader from '@/components/HomeLiveHeader.vue'
import Footer from '@/components/Footer.vue'
import HomeView from '../views/HomeView.vue'
import ModificationMdpView from '../views/ModificationMdpView.vue'
import HomeLiveView from '../views/HomeLiveView.vue'
import CommandeView from '../views/CommandeView.vue'
import CommandeStickyBottom from '@/components/CommandeStickyBottom.vue'
import PaiementRetourView from '../views/PaiementRetourView.vue'
import CommandeMessageDoneView from '../views/CommandeMessageDoneView.vue'

import ChoixCoureurView from '../views/ChoixCoureurView.vue'
import ChoixCoureurFinView from '../views/ChoixCoureurFinView.vue'

import FaqView from '../views/FaqView.vue'
import MentionsLegalesView from '../views/MentionsLegalesView.vue'
import DonneesPersonnellesView from '../views/DonneesPersonnellesView.vue'
import GestionCookiesView from '../views/GestionCookiesView.vue'
import CGLView from '../views/CGL.vue'
import HomeMarketingStickyBottom from '@/components/HomeMarketingStickyBottom.vue'
import HomeLiveStickyBottom from '@/components/HomeLiveStickyBottom.vue'
import PanelCompte from '@/components/PanelCompte.vue'

const routes = [
  /*{
    path: '/',
    name: 'home',
    component: HomeView
  },*/{
    path: '/',
    name: 'home',
    components:{
      default:HomeView,
      footer:Footer,
      header:HomeMarketingHeader,
      stickybottommobile:HomeMarketingStickyBottom,
      panelcompte:PanelCompte,
    },
    meta: {
      title: 'Grand Raid Réunion du 17 au 20 octobre 2024 - SFR Grand Raid',
      metaTags: [
        {
          name: 'description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        },
        {
          property: 'og:description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        }
      ]
    }
  },{
    path: '/commande',
    name: 'commande',
    components:{
      default:CommandeView,
      footer:Footer,
      header:HomeMarketingHeader,
      stickybottommobile:CommandeStickyBottom,
      panelcompte:PanelCompte,
    },
    meta: {
      title: 'SFR Grand Raid Réunion - Louer une balise',

      metaTags: [
        {
          name: 'description',
          content: 'Partagez votre course du Grand Raid de La Réunion en temps réel avec vos proches grâce à SFR, en louant la balise GPS Capturs.'
        },
        {
          property: 'og:description',
          content: 'Partagez votre course du Grand Raid de La Réunion en temps réel avec vos proches grâce à SFR, en louant la balise GPS Capturs.'
        }
      ]
    }
  },
  /*{
    path: '/commande/:commandeId',
    name: 'commandeEtat',
    components:{
      default:CommandeView,
      footer:Footer,
      header:HomeMarketingHeader,
      stickybottommobile:CommandeStickyBottom,
      panelcompte:PanelCompte,
    }
  },*/
  {
    path: '/modificationMdp/:codeid',
    name: 'modificationMdp',
    components:{
      default:ModificationMdpView,
      footer:Footer,
      header:HomeMarketingHeader,
      stickybottommobile:CommandeStickyBottom,
      panelcompte:PanelCompte,
    },
    meta: {
      title: 'SFR Grand Raid Réunion - Modifier mon mot de passe',
      metaTags: [
        {
          name: 'description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        },
        {
          property: 'og:description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        }
      ]
    }
  },
  {
    path: '/paiementRetour/:commandeId/:etatretour/:messageretour',
    name: 'paiementRetour',
    components:{
      default:PaiementRetourView,
      footer:Footer,
      header:HomeMarketingHeader,
      stickybottommobile:CommandeStickyBottom,
      panelcompte:PanelCompte,
    },
    meta: {
      title: 'SFR Grand Raid Réunion - Louer une balise',
      metaTags: [
        {
          name: 'description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        },
        {
          property: 'og:description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        }
      ]
    }
  },
  {
    path: '/commandeMessage/:commandeId',
    name: 'commandeMessage',
    components:{
      default:CommandeMessageDoneView,
      footer:Footer,
      header:HomeMarketingHeader,
      panelcompte:PanelCompte,
    },
    meta: {
      title: 'SFR Grand Raid Réunion - Louer une balise',
      metaTags: [
        {
          name: 'description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        },
        {
          property: 'og:description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        }
      ]
    }
  },{
    path: '/suivi-live',
    name: 'homeLive',
    components:{
      default:HomeLiveView,
      footer:Footer,
      header:HomeLiveHeader,
      stickybottommobile:HomeLiveStickyBottom,
      panelcompte:PanelCompte,
    },
    meta: {
      title: 'SFR Grand Raid Réunion - Suivi Live',
      metaTags: [
        {
          name: 'description',
          content: 'Proche, ami ou tout simplement curieux ? Recherchez et suivez en direct un coureur du Grand Raid de La Réunion grâce à son nom ou son numéro de dossard, en partenariat avec SFR.'
        },
        {
          property: 'og:description',
          content: 'Proche, ami ou tout simplement curieux ? Recherchez et suivez en direct un coureur du Grand Raid de La Réunion grâce à son nom ou son numéro de dossard, en partenariat avec SFR.'
        }
      ]
    }
  },
  {
    path: '/choix-coureur',
    name: 'choixcoureur',
    components:{
      default:ChoixCoureurView,
      footer:Footer,
      header:HomeMarketingHeader,
      stickybottommobile:HomeMarketingStickyBottom,
      panelcompte:PanelCompte,
    },
    meta: {
      title: 'SFR Grand Raid Réunion - Profil de Coureur',
      metaTags: [
        {
          name: 'description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        },
        {
          property: 'og:description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        }
      ]
    }
  },
  {
    path: '/commande-non-disponible',
    name: 'choixcoureurfin',
    components:{
      default:ChoixCoureurFinView,
      footer:Footer,
      header:HomeMarketingHeader,
      stickybottommobile:HomeMarketingStickyBottom,
      panelcompte:PanelCompte,
    },
    meta: {
      title: 'SFR Grand Raid Réunion - Commande non disponible',
      metaTags: [
        {
          name: 'description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        },
        {
          property: 'og:description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        }
      ]
    }
  },
  {
    path: '/faq',
    name: 'faq',
    components:{
      default:FaqView,
      footer:Footer,
      header:HomeMarketingHeader,
      stickybottommobile:HomeMarketingStickyBottom,
      panelcompte:PanelCompte,
    },
    meta: {
      title: 'SFR Grand Raid Réunion - Comment ça fonctionne?',
      metaTags: [
        {
          name: 'description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        },
        {
          property: 'og:description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        }
      ]
    }
  },
  {
    path: '/mentions-legales',
    name: 'mentionslegales',
    components:{
      default:MentionsLegalesView,
      footer:Footer,
      header:HomeMarketingHeader,
      stickybottommobile:HomeMarketingStickyBottom,
      panelcompte:PanelCompte,
    },
    meta: {
      title: 'SFR Grand Raid Réunion - Mentions Légales',
      metaTags: [
        {
          name: 'description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        },
        {
          property: 'og:description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        }
      ]
    }
  },
  {
    path: '/donnees-personnelles',
    name: 'donneespersonnelles',
    components:{
      default:DonneesPersonnellesView,
      footer:Footer,
      header:HomeMarketingHeader,
      stickybottommobile:HomeMarketingStickyBottom,
      panelcompte:PanelCompte,
    },
    meta: {
      title: 'SFR Grand Raid Réunion - Données Personnelles',
      metaTags: [
        {
          name: 'description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        },
        {
          property: 'og:description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        }
      ]
    }
  },
  {
    path: '/gestion-cookies',
    name: 'gestioncookies',
    components:{
      default:GestionCookiesView,
      footer:Footer,
      header:HomeMarketingHeader,
      stickybottommobile:HomeMarketingStickyBottom,
      panelcompte:PanelCompte,
    },
    meta: {
      title: 'SFR Grand Raid Réunion - Gestion des cookies',
      metaTags: [
        {
          name: 'description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        },
        {
          property: 'og:description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        }
      ]
    }
  },
  {
    path: '/conditions-generales-de-location',
    name: 'conditionsgeneraleslocation',
    components:{
      default:CGLView,
      footer:Footer,
      header:HomeMarketingHeader,
      stickybottommobile:HomeMarketingStickyBottom,
      panelcompte:PanelCompte,
    },
    meta: {
      title: 'SFR Grand Raid Réunion - Conditions Générale de location',
      metaTags: [
        {
          name: 'description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        },
        {
          property: 'og:description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        }
      ]
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
})
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});
export default router
