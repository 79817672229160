<template>
  <div class="gr-main-container">
    <section class=" mentions">
      <div class="uk-container">
        <h2 v-if="displayTitle=='show'">Conditions générales de location de matériel</h2>
        <p  v-if="displayTitle=='show'" class="uk-center">VERSION 2024</p>
        <h3 class="uk-padding-remove-top">ARTICLE 1 : OBJET</h3>
        <p>Les présentes Conditions Générales de Location (ci-après les « CGL ») sont éditées par la Société Réunionnaise de Radiotéléphonie dont le siège social est situé au 21 Rue Pierre Aubert – Zone du Chaudron – 97408 Saint Denis Cedex, immatriculée au Registre du commerce et des sociétés sous le numéro 393 551 007 (ci-après « SRR »).</p>
        <p>Ces CGL sont applicables entre la SRR et toute personne physique majeure (ci-après « le Coureur » ou le « Locataire ») louant un boitier GPS Capturs (ci-après « le Boîtier ») sur le site Internet https://grandraid.sfr.re/ dans le cadre de sa participation à la course annuelle « Le Grand Raid » se déroulant sur l’Île de la Réunion. Il est porté à la connaissance du Locataire que toute location dudit Boîtier implique la connaissance et l’acceptation sans réserve des présentes CGL.</p>
        <p>Les CGL applicables sont celles en vigueur à la date de la commande passée par le Locataire pour le Boîtier.</p>
        <h3>ARTICLE 2 – CONDITIONS GENERALES POUR CONTRACTER</h3>
        <p>Le Boîtier est exclusivement proposé à la location des coureurs participants à la course du Grand Raid sur l’Île de la Réunion sur le site Internet https://grandraid.sfr.re/</p>
        <h3>ARTICLE 3 – COMMANDE ET PAIEMENT</h3>
        <p>Le prix relatif à la location du Boîtier est celui en vigueur au jour de la commande effectuée par le Locataire. Il est exprimé en Euros Toutes Taxes Comprises (€ TTC). Le Locataire réalise en ligne la location du Boitier et procède au paiement.</p>
        <p>Le paiement de la commande du ou des Boîtier(s) s'effectue par carte bancaire sur le site Internet https://grandraid.sfr.re/.</p>
        <p>En cas de fraude constatée, la SRR se réserve le droit de poursuivre le Locataire.</p>
        <h3>ARTICLE 4 – DEPÔT DE GARANTIE</h3>
        <p>Un dépôt de garantie sera exigé au Locataire à titre de garantie financière en cas de non-restitution du ou des Boîtiers ou en cas de détérioration du ou des Boîtier(s), sous forme d’empreinte de la carte bancaire ayant servi au paiement.</p>
        <p>En cas de non-restitution après le 26 octobre 2024 ou de détérioration du ou des Boîtier(s) loué(s), la somme de quatre-vingt-dix-neuf euros toutes taxes comprises par Boîtier (99€ TTC/Boîtier) correspondant à sa valeur marchande, déduction faite du montant déjà payé par le Locataire pour la location du Boitier (3€ TTC), sera prélevée sur la carte bancaire du Locataire, soit un montant de quatre-vingt-seize euros toutes taxes comprises (96€ TTC).</p>
        <p>Par détérioration du Boîtier, il faut entendre par exemple (sans que cette liste ne soit limitative), la casse du Boitier, le rendant impropre à son usage.</p>
        <p>Les dommages d’origine externe ou consécutifs à une mauvaise utilisation du Boîtier ou à une utilisation non conforme, une intervention autre que celle des personnes habilitées par le constructeur, d’un choc, d’une exposition à l’humidité (oxydation) ainsi que la perte totale entraineront l’encaissement du dépôt de garantie.</p>
        <h3>ARTICLE 5 – MISE A DISPOSITION DU MATERIEL</h3>
        <p>Le jour de l’évènement du Grand Raid, le Locataire devra récupérer le ou les Boîtier(s) sur le stand SFR au moment du retrait de son dossard Grand Raid.</p>
        <p>Le Boitier sera testé par SRR en présence du Coureur et avant le départ du Grand Raid lors de la remise des dossards.</p>
        <p>A compter de la remise du Boitier, le Locataire reconnaît l’avoir reçu en parfait état de fonctionnement et en sera responsable.</p>
        <h3>ARTICLE 6 – PROPRIETE DU MATERIEL</h3>
        <p>SRR conserve la propriété du Boitier mis en location.</p>
        <p>Lors de la remise du Boitier, la charge des risques est transférée au Locataire qui en assume la garde matérielle et juridique sous son entière responsabilité.</p>
        <p>La Location et la garde juridique afférente prennent fin le jour où le Boitier est restitué par le Locataire en bon état.</p>
        <h3>ARTICLE 7 – DUREE DE LOCATION</h3>
        <p>La Location prend effet au moment où le matériel est mis à disposition du Locataire. La durée de location est fixée à cinq (5) jours.</p>
        <p>Le Locataire s’engage à remettre le Boitier à SRR à la fin de la Course sur le stand SFR, ou à le restituer directement en boutiques SFR participantes au plus tard le 26 octobre 2024.</p>
        <h3>ARTICLE 8 – RESPONSABILITÉ</h3>
        <p>Quelles que soient les modalités de transport, le Coureur a la garde du Boîtier et en est responsable dès que celui-ci lui est remis en mains propres au stand SFR du Grand Raid. Le Coureur qui transporte le Boîtier s'engage à le conserver dans les meilleures conditions possibles. Ce dernier s’engage notamment à ne pas utiliser le Boîtier à des fins illégales ou autres que celles prévues aux présentes, et de quelle que nature qu’elle soit.</p>
        <p>SRR ne saurait être tenue pour responsable en cas :</p>
        <ul>
          <li>de fait du Locataire lui-même ;</li>
          <li>de fait, imprévisible et insurmontable, d’un tiers étranger au contrat ;</li>
          <li>de force majeure telle que définie à l’article 1218 du code civil ;</li>
          <li>d’une mise en danger du Locataire telle qu’une action propre à l’éloigner des sentiers prévus dans le cadre de l’évènement.</li>
        </ul>
        <p>En cas d’inexécution contractuelle par l’une des parties, l’autre partie lésée peut:</p>
        <ul>
          <li>refuser d’exécuter ou suspendre l’exécution de sa propre obligation</li>
          <li>demander réparation des conséquences de l’inexécution.</li>
        </ul>
        <p>En cas de non-restitution dans les délais prévus à l’article 7 ou de détérioration du Boîtier, SRR pourra prélever le dépôt de garantie tel que précisé à l’article 4 des présentes.</p>
        <p>Dans le cadre des présentes CGL, SRR est soumis à une obligation de moyens.</p>
        <p>Toutefois, en aucun cas ni une réduction du prix ni la résolution unilatérale du contrat, telle que prévue par les articles 1217, 1223 et 1226 du code civil, ne peuvent être invoquées.</p>
        <h3>ARTICLE 9 - DROIT DE RÉTRACTATION</h3>
        <p>Conformément aux dispositions des articles L. 221-18 et suivants du Code de la Consommation, le Locataire bénéficie d’un délai de rétractation de quatorze (14) jours à compter de la conclusion du contrat lequel se matérialise par la réception d’un email de confirmation de la location assortie de la délivrance de la facture correspondant au montant payé.</p>
        <p>Pour se rétracter, le Locataire doit, avant l’expiration du délai de 14 jours, adresser à SRR toute déclaration sur supports durables (courrier, email…) dénuée d'ambiguïté exprimant sa volonté de se rétracter. La charge de la preuve du respect de ce délai par le Coureur lui incombe.</p>
        <p>Dès lors que le droit de rétractation est exercé, SRR sera tenue de rembourser au Locataire l’intégralité des sommes versées au plus tard dans les 14 jours à compter de la réception de la demande de rétractation.</p>

        <h3>ARTICLE 10 – GARANTIE</h3>
        <p>SRR s’engage à assurer le bon fonctionnement du Boîtier. En cas de dysfonctionnement constaté lors du test, SRR s’engage à fournir un autre Boîtier au Locataire.</p>

        <h3>ARTICLE 11 - DONNÉES PERSONNELLES</h3>
        <h4>1. Lois applicables</h4>
        <p>SRR (ou la « Société ») prend les mesures propres à assurer la protection et la confidentialité des données à caractère personnel (« DCP ») en sa possession concernant les Clients et les traite dans le respect des dispositions de la loi n° 78-17 du 6 janvier 1978 modifiée relative à l’informatique, aux fichiers et aux libertés et de la réglementation européenne en vigueur sur la protection des DCP (« Réglementation »).</p>
        <h4>2. Contact</h4>
        <p>Le responsable du traitement des DCP est SRR, dont le siège est sis 21 rue Pierre Aubert ZI du Chaudron 97490 Sainte-Clotilde, représentée par son directeur général adjoint. Le délégué à la protection des données (ci-après « DPO ») peut être contacté à l’adresse suivante : Service Client SFR, DPO - Délégué à la Protection des Données – 21 rue Pierre Aubert CS 62001 - 97743 St Denis Cedex.</p>
        <h4>3. Nature des données</h4>
        <p>Les DCP sont collectées par la Société dans le cadre de la souscription et de l’utilisation du Boitier par le Client. La géolocalisation du Locataire est obligatoire pour l’exécution du contrat de par la finalité de la Location. La géolocalisation ne sera proposée que sur le parcours défini dans le cadre de la course et uniquement pendant son déroulement.</p>
        <h4>4. Finalités</h4>
        <p>Le traitement des DCP a pour principale finalité l'exécution du contrat: la facturation de la location du boîtier de géolocalisation, la gestion de la facture du Client y compris la prévention des impayés et la lutte contre la fraude, et le suivi du coureur pendant la course.</p>
        <p>Ce suivi s’effectue de la façon suivante : l’acheteur de la balise va entrer le numéro de dossard sur le site de capturs ou lors de la remise de la balise en cas de location. Capturs fournit alors les coordonnées GPS du dossard concerné à SFR uniquement pendant la durée de la course et uniquement sur le trajet des courses du grand raid.</p>
        <p>La fourniture des DCP par le Client conditionne la conclusion et, dans certains cas, l’exécution du contrat par la Société.</p>
        <p>Sous réserve du consentement du client, la société pourra utiliser ses données personnelles dans le cadre d’opérations de marketing relatives à l’objet social de la société, par email, courrier postal, téléphone ou SMS. Le client aura la possibilité de retirer son consentement à tout moment à l'utilisation des DCP de manière simple et sans frais.</p>
        <h4>5. Durées de conservation</h4>
        <p>Les DCP sont conservées pour la durée de la fourniture des Services et/ou pour la durée des traitements spécifiques auxquels le Client a consenti et/ou pour les durées légales de conservation. La carte des courses et les données associées sont supprimées dans les 10 jours qui suivent.</p>
        <h4>6. Destinataires</h4>
        <p>Les DCP pourront être traitées par le personnel habilité de la Société, et son partenaire Capturs. Le recours à ce prestataire est nécessaire à la bonne exécution du contrat passé entre la Société et le Client. Le prestataire Capturs, sis 425 rue Jean Rostand, 31670 LABEGE, France assure la réalisation technique des finalités des présentes conditions générales de location.</p>
        <h4>7. Données de géolocalisation</h4>
        <p>La société communique à l'organisation du Grand Raid les données de géolocation du client durant toute la course. Ces données seront uniquement utilisées dans le cadre des courses du Grand Raid 2024.</p>
        <h4>8. Enregistrement des appels</h4>
        <p>Le Client est informé que ses conversations avec le Service Client sont susceptibles d’être enregistrées ou écoutées par la Société à des fins probatoires et de détection des fraudes et/ou d’être étudiées afin d’améliorer la qualité du service, notamment à des fins de formation. Ces conversations sont conservées pour une durée de six mois maximum à compter de leur enregistrement sauf réglementation imposant une durée de conservation supérieure.</p>
        <h4>9. Droits des personnes</h4>
        <p>AA tout moment, le Client peut demander l'accès aux DCP le concernant, recueillies dans le cadre des finalités détaillées ci-dessus, la rectification ou l'effacement de celles-ci (dans la mesure où cela n’empêche pas la bonne exécution du contrat ou le respect des obligations légales de la Société) et la limitation d’un ou plusieurs traitements particuliers le concernant, dans les conditions prévues par la Réglementation. Le Client dispose également du droit de s'opposer à un traitement de ses DCP et du droit à leur portabilité, dans les conditions fixées par la Réglementation. De manière générale, le Client a le droit de comprendre et interroger la Société à propos de l’utilisation qui est faite de ses DCP.</p>
        <p>Il peut exercer ces droits :</p>
        <ul>
          <li>en envoyant un courrier postal à : Service Client SFR, DPO - Délégué à la Protection des Données – 21 rue Pierre Aubert CS 62001 - 97743 St Denis Cedex. Il devra fournir son nom, prénom, numéro d’appel ainsi qu’une copie d’une pièce d’identité.</li>
          <li>par voie électronique : <a href="mailto:donneespersonnelles@srr.fr">donneespersonnelles@srr.fr</a></li>
        </ul>
        <p>Lorsqu’un traitement de DCP est fondé sur le recueil de son consentement, le Client dispose du droit de retirer ledit consentement à tout moment, sans porter atteinte à la licéité du traitement fondé sur le consentement effectué avant le retrait de celui-ci, via les mentions contenues dans chaque message publicitaire, via SMS/MMS/Email ou via son espace personnel. Le Client a le droit d'introduire une réclamation auprès de la CNIL. Le Client est informé que les DCP le concernant sont conservées ou supprimées après son décès conformément à la Réglementation, et qu’il dispose du droit de donner instruction de communiquer ou non ces données à un tiers qu’il aura préalablement désigné.</p>
        <h4>10. Prévention des impayés</h4>
        <p>Afin de se prémunir contre d’éventuels impayés et de lutter contre les fraudes, SRR pourra interroger le GIE Carte bancaire.</p>
        <h3>ARTICLE 12 – PROPRIETE INTELLECTUELLE</h3>
        <p>SRR est propriétaire des Boîtiers. A ce titre, les présentes CGL ne constituent pas une cession ou licence de tous brevet, logiciel, de tout droit d’auteur ou tout autre droit de propriété intellectuelle attaché au Boitier accordée au Locataire. Toute utilisation du Boîtier autre que celle du Grand Raid est interdite.</p>
        <h3>ARTICLE 13 - LOI APPLICABLE</h3>
        <p>Les parties conviennent que la loi applicable aux présentes conditions générales est la loi française. Tout litige relatif à l’exécution des présentes CGL sera de la compétence exclusive des juridictions françaises de Saint-Denis de la Réunion.</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'CGL',
  props: {
    msg: String,
    displayTitle: String
  }
}
</script>
