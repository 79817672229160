<template>
  <h2 class="gr-texte-black uk-margin-medium-bottom">Récapitulatif de vos informations</h2>

  <div uk-grid>
      <div id="gr_vos_informations_2_recapitulatif_infos" class="uk-width-1-1 uk-width-3-4@m uk-margin-large-bottom" uk-grid>
          <div class="uk-width-2-3 uk-width-1-2@s">
              <div class="label">Nom</div>
              <div class="value gr-texte-black">{{nom}}</div>
          </div>
          <div class="uk-width-4-5 uk-width-1-2@s">
              <div class="label">Prénom</div>
              <div class="value gr-texte-black">{{prenom}}</div>
          </div>
          <div class="uk-width-4-5 uk-width-1-2@s">
              <div class="label">Email</div>
              <div class="value gr-texte-black">{{email}}</div>
          </div>
          <div class="uk-width-4-5 uk-width-1-2@s">
              <div class="label">Téléphone</div>
              <div class="value gr-texte-black">{{telephone}}</div>
          </div>
          <div class="uk-width-4-5 uk-width-1-2@s">
              <div class="label">Adresse</div>
              <div class="value gr-texte-black">{{adresse}}</div>
          </div>
          <div class="uk-width-4-5 uk-width-1-2@s">
              <div class="label">Code Postal</div>
              <div class="value gr-texte-black">{{codepostal}}</div>
          </div>
          <div class="uk-width-4-5 uk-width-1-2@s">
              <div class="label">Ville</div>
              <div class="value gr-texte-black">{{ville}}</div>
          </div>
          <div class="uk-flex uk-flex-right uk-width-1-1 uk-margin-large-top uk-visible@s">
              <button class="uk-button cta-sfr-primary" id="btn-valider-step2" @click="gotoStep3()">VALIDER</button>
          </div>
      </div>
      <div class="uk-width-1-4 uk-hidden@s"></div>
  </div>

</template>

<script>
import axios from 'axios';
import { mapMutations } from 'vuex'
export default {
  name: 'PanelCommandeStep2Authenticated',
  components: {
  },
  mounted(){
    if(this.authenticated&&this.tokenAuth!=""){
      //var vm = this;
      axios.post(this.$store.state.urlws+'mesInfos',{'codesession': this.codesession},{headers:{Authorization:'Bearer '+this.tokenAuth+''}})
      .then(response => {
        this.nom=response.data.infos.nom;
        this.prenom=response.data.infos.prenom;
        this.adresse=response.data.infos.adresse;
        this.codepostal=response.data.infos.codepostal;
        this.ville=response.data.infos.ville;
        this.email=response.data.infos.email;
        this.telephone=response.data.infos.telephone;
      })
      .catch(e => {
        this.$store.commit('deconnexion',{});

        //this.$router.push({ name: 'commande' });
      })
    }
    else{
      this.$router.push({ name: 'commande' });
    }
  },
  methods:{
    gotoStep1(){
      this.$store.commit('gotoStep',{stepCommande:1});
    },
    gotoStep3(){
      this.$store.commit('gotoStep',{stepCommande:3});
    },
  },
  props: {
    msg: String,
  },

  data() {
    return {
      nom: "",
      prenom: "",
      adresse: "",
      codepostal: "",
      ville: "",
      email: "",
      telephone:""

    }

  },
  computed:{
    tokenAuth () {
      return this.$store.state.tokenAuth
    },
    authenticated() {
      return this.$store.state.authenticated
    },
    codesession() {
      return this.$store.state.codesession
    },
  }
}
</script>
