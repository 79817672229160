<template>
  <div id="mon_compte" class="uk-width-1-1">
      <h2 class="gr-texte-black uk-margin-small-bottom">MON COMPTE</h2>
      <h3 class="gr-texte-black uk-margin-small-top">MES INFORMATIONS</h3>
      <div id="mon_compte_infos" uk-grid>
          <div class="uk-width-1-1 uk-width-4-5@s uk-margin-remove" uk-grid>
              <div class="uk-width-1-1">
                <label class="uk-form-label gr-form-label gr-texte-black" for="form-stacked-newpassword">Nouveau mot de passe*</label>
                <div class="uk-form-controls">
                    <input class="uk-input uk-width-1-1 gr-form-input" v-model="input.newpassword" name="newpassword" id="form-stacked-newpassword" type="password" placeholder="Votre nouveau mot de passe">
                    <span class="error-message">{{error.newpassword}}</span>
                </div>
              </div>
              <div class="uk-width-1-1">
                <label class="uk-form-label gr-form-label gr-texte-black" for="form-stacked-confirm_password">Confirmer le nouveau mot de passe*</label>
                <div class="uk-form-controls">
                    <input class="uk-input uk-width-1-1 gr-form-input" v-model="input.confirm_password" name="confirm_password" id="form-stacked-confirm_password" type="password" placeholder="Confirmer votre nouveau mot de passe">
                    <span class="error-message">{{error.confirm_password}}</span>
                </div>
              </div>
              <div class="uk-width-1-1">
                <label class="uk-form-label gr-form-label gr-texte-black" for="form-stacked-password">Ancien mot de passe*</label>
                <div class="uk-form-controls">
                    <input class="uk-input uk-width-1-1 gr-form-input" v-model="input.password" name="password" id="form-stacked-password" type="password" placeholder="Votre ancien mot de passe">
                    <span class="error-message">{{error.password}}</span>
                </div>
              </div>
              <div class="uk-margin uk-width-1-1">
                <div v-if="errors" class="uk-margin uk-alert-danger" uk-alert>
                   <a class="uk-alert-close" uk-close></a>
                  {{errors}}
                </div>
              </div>
          </div>
          <div class="uk-width-1-5 uk-hidden@s"></div>
      </div>
      <div class="uk-flex uk-flex-column uk-flex-top">
          <button @click="registerMdpMod()" class="uk-button cta-sfr-primary uk-margin-large-top uk-margin-small-bottom">Enregistrer</button>
          <button @click="changeStepMC('mesinformations')" class="uk-button cta-sfr-light">Annuler</button>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'MonCompteMdpMod',
  data() {
    return {
      input: {
        password:"",
        confirm_password:"",
        newpassword:"",
      },
      errors:null,
      error:{
        password:null,
        confirm_password:null,
        newpassword:null,
      }
    }
  },
  computed: {
    tokenAuth () {
      return this.$store.state.tokenAuth
    },
    authenticated() {
      return this.$store.state.authenticated
    },
    codesession() {
      return this.$store.state.codesession
    },
  },
  methods: {
      changeStepMC(label){
        this.$store.commit('gotoStepMC',{stepMC:label});
      },
      registerMdpMod(){
        if(this.input.password != "" && this.input.confirm_password != "" && this.input.newpassword != "" ) {
        axios.put(this.$store.state.urlws+'usermdp',
          {
            password:this.input.password,
            confirm_password:this.input.confirm_password,
            newpassword:this.input.newpassword,
            codesession:this.codesession,
          },{headers:{Authorization:'Bearer '+this.tokenAuth}}
        )
        .then(response => {
          this.changeStepMC("mesinformations");
          // JSON responses are automatically parsed.
          //this.posts = response.data

        })
        .catch(e => {
          if(e.code=="ERR_NETWORK" && !e.response.data){
            this.$store.commit('deconnexion');
          }
          else{
            let message = "Une erreur est survenue.";
            if(e.response){
              if(e.response.data){
                if(e.response.data.action=="deconnexion"){
                  this.$store.commit('deconnexion');
                }
                else{
                  if(e.response.data.messages){
                    if(e.response.data.messages.errors){
                      this.error.password = e.response.data.messages.errors.password;
                      this.error.confirm_password = e.response.data.messages.errors.confirm_password;
                      this.error.newpassword = e.response.data.messages.errors.newpassword;
                      this.errors = e.response.data.error;
                    }
                  }
                }
              }
              this.errors=message;
            }
          }

        })
        }
        else{
          this.errors = "Vous devez renseigner tous les champs";
        }
      },
  }
}
</script>
