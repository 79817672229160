<template>
  <div class="uk-container" id="tostiky-panel">
    <div class="uk-width-1-1 uk-grid-collapse" uk-grid>
      <div id="messageRetourPaiement" class="uk-width-1-1 uk-width-1-2@s uk-width-2-3@m uk-margin-small-top">
        <div v-if="etat==0">
          <h2 class="gr-text-black">Echec du paiement</h2>
          <p class="gr-text-black">Le paiement a échoué. ({{messageRetour}})</p>
        </div>
        <div v-if="etat==1">
          <h2 class="gr-text-black">Paiement effectué</h2>
          <p class="gr-text-black">Le paiement de votre location a été enregistré. Un mail récapitulatif de votre location vous a été envoyé.</p>
        </div>
      </div>
      <div id="gr_details_commande" class="uk-width-1-1 uk-width-1-2@s uk-width-1-3@m uk-visible@s">
        <PanelRecapitulatif :idCommande="this.$route.params.commandeId" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapMutations } from 'vuex'
import PanelRecapitulatif from '@/components/PanelRecapitulatif.vue'

export default {
  name: 'PaiementRetourView',
  components: {
    PanelRecapitulatif
  },
  data(){
    return {
      messageRetour:null,
      etat:null,
      commandeid:null,
    }
  },
  mounted(){
    this.messageRetour=atob(this.$route.params.messageretour);
    this.etat=this.$route.params.etatretour;
    this.commandeId=this.$route.params.commandeId;
  },
}
</script>
