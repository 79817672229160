<template>
  <router-link class="uk-button cta-sfr-primary">{{ texte }}</router-link>
</template>

<script>
export default {
  name: 'BoutonPrimary',
  props: {
    texte: String,
  }
}
</script>
