<template>
  <div class="uk-container" id="tostiky-panel">
    <div class="uk-width-1-1 uk-grid-collapse" uk-grid>
      <div id="messageRetourPaiement" class="uk-width-1-1 uk-width-1-2@s uk-width-2-3@m uk-margin-small-top">
        <h2 class="gr-text-black">Votre commande est faite</h2>
        <p class="gr-text-black">Un mail récapitulatif de votre commande vous a été envoyé.</p>
        <p>&nbsp;</p>
      </div>
      <div id="gr_details_commande" class="uk-width-1-1 uk-width-1-2@s uk-width-1-3@m uk-visible@s">
        <PanelRecapitulatif :idCommande="this.$route.params.commandeId" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapMutations } from 'vuex'
import PanelRecapitulatif from '@/components/PanelRecapitulatif.vue'

export default {
  name: 'CommandeMessageDoneView',
  components: {
    PanelRecapitulatif
  },
  data(){
    return {
      commandeid:null,
    }
  },
  mounted(){
    this.commandeId=this.$route.params.commandeId;
  },
}
</script>
