<template>
  <div class="gr-main-container">
    <section class="mentions">
      <div class="uk-container">
        
      <h2>Merci de votre intérêt</h2>

      <p>La location de balise sera disponible à partir du 1er octobre</p>

      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ChoixCoureurFin',
  props: {
    msg: String
  }
}
</script>
