<template>
  <div id="gr_step_component">
      <div class="circle  circle-active-step-1 "><span class="active-number-step-1">1</span></div>
      <div class="divider active-divider-1"></div>
      <a  @click="gotoStep2()"><div class="circle  circle-active-step-2"><span class="active-number-step-2">2</span></div></a>
      <div class="divider active-divider-2"></div>
      <a  @click="gotoStep3()"><div class="circle  circle-active-step-3"><span class="active-number-step-3">3</span></div></a>
      <div class="divider active-divider-3"></div>
      <div class="circle circle-active-step-4 active"><span class="active-number-step-4">4</span></div>
  </div>
  <h1 class="gr-texte-black uk-margin-large-bottom gr-moins-gros">FINALISATION DE VOTRE COMMANDE</h1>
  <h2>Les conditions générales de location</h2>

  <div id="finalisation_commande_conditions_generales" v-on:scroll="onCGLScroll()" class="uk-width-1-1">
    <div id="cglContent" >
     <CGL displayTitle="false" />
    </div>
  </div>
  <div id="finalisation_commande_checkbox" class="uk-margin-medium-top">
      <label class="gr-texte-black"><input v-if="!isRead" id="acceptationCGLdesabled" class="uk-checkbox" disabled="disabled"/><input v-if="isRead" id="acceptationCGL" class="uk-checkbox" v-model="input.acceptationCGL"  type="checkbox" v-on:click="changeClassButton">J'ai pris connaissance et accepte les Conditions Générales de location</label>
      <div v-if="errorsnotread" class="uk-margin uk-alert-danger" uk-alert>
        {{errorsnotread}}
      </div>
  </div>
  <div class="uk-margin uk-width-1-1">
    <div v-if="errors" class="uk-margin uk-alert-danger" uk-alert>
      {{errors}}
    </div>
  </div>
  <div class="uk-width-1-1 uk-flex uk-flex-right uk-margin-small-top uk-visible@s">
      <button id="btnPaiement" @click="commander()" class="uk-button cta-sfr-primary gr-disabled">Je valide ma commande</button>
  </div>
  <div class="uk-hidden">
    <form id="paiementform" method="POST" action="">
      <input type="hidden" name="codesession" :value="codesession" />
      <input type="hidden" name="coid" id="coid" value="" />
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import FormData from 'form-data';
import { mapMutations } from 'vuex'
import CGL from '@/components/CGL.vue'
export default {
  name: 'PanelCommandeStep3',
  components: {
    CGL,
  },
  data() {
    return {
      input: {
        acceptationCGL:false,
      },
      isRead:false,
      errors:null,
      errorsnotread:"Vous devez lire toutes les conditions générales de location.",
    }
  },
  methods:{
    changeClassButton(){
      let elem =  document.getElementById("acceptationCGL");
      let elem0 = document.getElementById("btnPaiement");
      let elemsimupaiement = document.getElementById("simuclickpaiement");

      if(!elem.checked){
        elem0.classList.add("gr-disabled");
        elemsimupaiement.classList.add("gr-disabled");
      }
      else{
        elem0.classList.remove("gr-disabled");
        elemsimupaiement.classList.remove("gr-disabled");
      }
    },
    onCGLScroll(){
      if(!this.isRead){
        let elem0 = document.getElementById("cglContent");
        let elem = document.getElementById("finalisation_commande_conditions_generales");
        let maxscroll=elem0.clientHeight-elem.clientHeight-20;
        if(elem.scrollTop>=maxscroll){
          this.isRead=true;
          this.errorsnotread=null;
        }
      }
    },
    gotoStep1(){
      this.$store.commit('gotoStep',{stepCommande:1});
    },
    gotoStep2(){
      this.$store.commit('gotoStep',{stepCommande:2});
    },
    commander(){
      if(!this.input.acceptationCGL){
        //this.errors="Vous devez lire et accepter les conditions générales de location!";
      }
      else{
        //formid.action=this.$store.state.urlpaiementpage+'paiement/do';
        axios.post(this.$store.state.urlws+'shop',{'quantite':this.nbarticle,'price':this.productPrice,'codesession': this.codesession},{headers:{Authorization:'Bearer '+this.tokenAuth+''}})
        .then(response => {
          //this.$router.push({ path: '/commandeMessage/'+response.data.idCommande });
          let formid=document.getElementById('paiementform');
          formid.action=this.$store.state.urlpaiementpage+'do';
          let coid=response.data.idCommande;
          let coidinput=document.getElementById('coid');
          coidinput.value=coid;
          formid.submit();
          // JSON responses are automatically parsed.
          //this.posts = response.data

        })
        .catch(e => {
          if(e.code=="ERR_NETWORK" && !e.response.data){
            this.$store.commit('deconnexion');
          }
          else{
            let message = "Une erreur est survenue.";
            if(e.response){
              if(e.response.data){
                if(e.response.data.action=="deconnexion"){
                  this.$store.commit('deconnexion');
                }
                else{
                  if(e.response.data.messages){
                    if(e.response.data.messages.errors){
                      if(e.response.data.messages.errors.quantite){
                        message+=e.response.data.messages.errors.quantite;
                      }
                    }
                  }
                }
              }
              this.errors=message;
            }
          }

        })
      }
    }
  },
  props: {
    msg: String,
  },
  computed:{
    wsurl(){
      return this.$store.state.wsurl;
    },
    nbarticle() {
      return this.$store.state.panier.nb;
    },
    productPrice() {
      return this.$store.state.productPrice;
    },
    authenticated() {
      return this.$store.state.authenticated
    },
    tokenAuth () {
      return this.$store.state.tokenAuth
    },
    codesession() {
      return this.$store.state.codesession
    },
  }
}
</script>
