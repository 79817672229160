<template>

    <Commande msg="" />

</template>

<script>
// @ is an alias to /src
import Commande from '@/components/Commande.vue'

export default {
  name: 'CommandeView',
  components: {
    Commande,
  }
}
</script>
