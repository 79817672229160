<template>
  <div class="uk-grid-small uk-width-3-4" uk-grid>
      <div class="uk-width-1-1 uk-flex uk-flex-middle"><h2>Se connecter au compte client</h2></div>
  </div>
  <form class="uk-form-stacked uk-flex uk-flex-top uk-width-1-1 uk-width-3-4@m uk-grid-small" uk-grid>
      <div class="uk-margin uk-width-1-1 uk-width-1-2@m uk-margin-top">
          <label class="uk-form-label" for="form-stacked-email">Email*</label>
          <div class="uk-form-controls">
              <input class="uk-input uk-width-1-1" id="form-stacked-email" v-model="input.email" name="email" type="text" placeholder="Email">
              <span class="error-message"></span>
          </div>
      </div>
      <div class="uk-margin uk-width-1-1 uk-width-1-2@m">
          <label class="uk-form-label" for="form-stacked-password">Mot de passe*</label>
          <div class="uk-form-controls">
              <input class="uk-input uk-width-1-1" id="form-stacked-password"  name="password"  v-model="input.password" type="password" placeholder="Mot de passe">
              <span class="error-message"></span>
          </div>
      </div>
      <div class="uk-margin uk-width-1-1">
        <div v-if="errors" class="uk-margin uk-alert-danger" uk-alert>
           <a class="uk-alert-close" uk-close></a>
          {{errors}}
        </div>
      </div>
      <div class="uk-margin uk-width-1-1  uk-margin-remove-top uk-flex uk-flex-column">
        <div class="uk-align-center uk-margin-remove-top uk-margin-remove-bottom uk-visible@s">
          <button type="Button" class="uk-button cta-sfr-primary" id="logincommande" v-on:click="login()">Se connecter</Button>
        </div>
        <div class="uk-align-center uk-margin-remove-top uk-margin-remove-bottom">
          <a href="" class="gr-link-form">Mot de passe oublié&nbsp;?</a>
        </div>
        <div class="uk-align-center uk-margin-remove-top uk-margin-remove-bottom">
          <a class="gr-link-form" id="btn-creer-compte" v-on:click="gotoregister()">Créer un compte&nbsp;?</a>
        </div>
      </div>
  </form>
</template>

<script>
import axios from 'axios';
import { mapMutations } from 'vuex'
export default {
  name: 'PanelCommandeStep2Login',
  data() {
      return {
          input: {
              email: "",
              password: ""
          },
          errors:null,

      }
  },
  methods:{
    gotoregister(){
      this.$store.commit('showFormLogin',{showformlogin:false});
    },
    gotoStep1(){
      this.$store.commit('gotoStep',{stepCommande:1});
    },
    gotoStep3(){
      this.$store.commit('gotoStep',{stepCommande:3});
    },
    login() {
        if(this.input.email != "" && this.input.password != "") {

            axios.post(this.$store.state.urlws+'login',{email:this.input.email,password:this.input.password})
            .then(response => {
              this.$store.commit('loginto',{isAuthenticate:true,tokenAuth:response.data.token,codesession:response.data.codesession});
              // JSON responses are automatically parsed.
              //this.posts = response.data

            })
            .catch(e => {
              this.errors = e.response.data.error;
            })

        } else {
          this.errors = "Vous devez renseigner tous les champs";
        }
    }
  },
  props: {
    msg: String
  },
  computed: {
    tokenAuth () {
      return this.$store.state.tokenAuth
    }
  },
}
</script>
