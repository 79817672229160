<template>
  <div class="uk-container" >
    <div class="uk-grid-small uk-width-3-4" uk-grid>
        <div class="uk-width-1-1 uk-width-1-2@m uk-flex uk-flex-middle"><h2>Modifier votre mot de passe</h2></div>
    </div>
    <form  method="post" action="#" class="uk-form-stacked uk-flex uk-flex-top uk-width-1-1 uk-width-3-4@m uk-grid-small" uk-grid>
        <div class="uk-margin uk-width-1-1 uk-width-1-2@m">
            <label class="uk-form-label" for="form-stacked-text">Nouveau Mot de passe*</label>
            <div class="uk-form-controls">
                <input class="uk-input uk-width-1-1" v-model="input.password" name="password" id="form-stacked-text" type="password" placeholder="Votre mot de passe">
                <span class="error-message">{{error.password}}</span>
            </div>
        </div>
        <div class="uk-margin uk-width-1-1 uk-width-1-2@m">
            <label class="uk-form-label" for="form-stacked-confirm_password">Confirmer votre mot de passe*</label>
            <div class="uk-form-controls">
                <input class="uk-input uk-width-1-1" v-model="input.confirm_password" name="confirm_password" id="form-stacked-confirm_password" type="password" placeholder="Votre mot de passe">
                <span class="error-message">{{error.password}}</span>
            </div>
        </div>
        <div class="uk-margin uk-width-1-1">
          <div v-if="errors" class="uk-margin uk-alert-danger" uk-alert>
             <a class="uk-alert-close" uk-close></a>
            {{errors}}
          </div>
        </div>
    </form>
    <div class="uk-grid-small uk-width-3-4" uk-grid>
      <div class="uk-flex uk-flex-right uk-width-1-1 uk-margin-large">
          <button class="uk-button cta-sfr-primary"  @click="changeMDP()">VALIDER</button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
export default {
  name: 'ModificationMdpView',
  components: {
  },
  data() {
      return {
          input: {
            password:"",
            confirm_password:""
          },
          errors:null,
          error:{
            password:null,
            confirm_password:null
          }
      }
  },

  methods:{
    changeMDP(){
      if(this.input.password != "" && this.input.confirm_password != "") {
      axios.post(this.$store.state.urlws+'changeMDP',
        {
          password:this.input.password,
          confirm_password:this.input.confirm_password,
          codeid:this.$route.params.codeid
        }
      )
      .then(response => {
        // JSON responses are automatically parsed.
        //this.posts = response.data
        this.errors = "Votre mot de passe a été modifié";
        this.input.password="";
        this.input.confirm_password="";
      })
      .catch(e => {
        this.error.password = e.response.data.messages.errors.password;
        this.error.confirm_password = e.response.data.messages.errors.confirm_password;
        this.errors = e.response.data.error;
      })
      }
      else{
        this.errors = "Vous devez renseigner tous les champs";
      }
    },
  },
}
</script>
