<template>
  <div class="gr-sticky-bottom-container uk-hidden@s" >
    <div class="uk-container uk-flex uk-flex-center uk-flex-middle uk-flex-wrap">
      <BoutonPrimary :to="'/commande'" texte="je réserve ma balise GPS" class="gr-margin-5" target="_self" href="/commande" />
      <a class="uk-button cta-sfr-secondary-infos" href="#marketing-infos-section" uk-scroll><svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                  <path d="M19.7996 32.6759C19.3484 32.2436 19.1231 31.7052 19.1231 31.0605C19.1231 30.4164 19.3484 29.878 19.7996 29.4453C20.2505 29.0129 20.8256 28.7964 21.5253 28.7964C22.2062 28.7964 22.7679 29.0129 23.2098 29.4453C23.6515 29.878 23.8725 30.4164 23.8725 31.0605C23.8725 31.7052 23.6515 32.2436 23.2098 32.6759C22.7679 33.1086 22.2062 33.3248 21.5253 33.3248C20.8256 33.3248 20.2505 33.1086 19.7996 32.6759ZM27.9451 13.1571C29.1139 14.1971 29.6985 15.6283 29.6985 17.4507C29.6985 19.2548 29.1186 20.6308 27.959 21.5786C26.7993 22.5269 25.2621 23.0006 23.3477 23.0006L23.2373 26.7255H19.8411L19.7029 20.4327H20.9455C22.5471 20.4327 23.78 20.2214 24.6456 19.7977C25.5105 19.3745 25.9433 18.6013 25.9433 17.4782C25.9433 16.6685 25.7086 16.0244 25.2391 15.5454C24.7698 15.067 24.1299 14.8275 23.3201 14.8275C22.4732 14.8275 21.8153 15.0579 21.3458 15.5178C20.8764 15.9781 20.6419 16.6133 20.6419 17.423H16.9971C16.9784 16.3005 17.218 15.2969 17.7149 14.4134C18.212 13.5298 18.9437 12.8395 19.9101 12.3425C20.8764 11.8455 22.0129 11.5969 23.3201 11.5969C25.2344 11.5969 26.776 12.1172 27.9451 13.1571Z" fill="#E31E26"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2 22.3428C2 33.5171 11.0586 42.5758 22.2329 42.5758C33.4073 42.5758 42.4659 33.5171 42.4659 22.3428C42.4659 11.1684 33.4073 2.10999 22.2329 2.10999C11.0586 2.10999 2 11.1684 2 22.3428Z" stroke="#E31E26" stroke-width="2.70723" stroke-miterlimit="2"/>
                  </svg>
      </a>
    </div>
  </div>
</template>

<script>

import BoutonPrimary from '@/components/BoutonPrimary.vue'
export default {
  name: 'HomeMarketingStickyBottom',
  components: {
    BoutonPrimary,
  },
  props: {
    msg: String
  }
}
</script>
